import * as React from "react";
import { User } from "../types";
import { removeUser } from "../redux/actions";
import { connect } from "react-redux";

interface Props {
  user: User;
  removeUser: (user: User) => void;
}

class UserRow extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.removeUser = this.removeUser.bind(this);
  }

  removeUser() {
    if (
      window.confirm("Are you sure you wish to remove the user from Lemur?")
    ) {
      const user = this.props.user;
      this.props.removeUser(user);
    }
  }

  public render() {
    const user = this.props.user;
    return (
      <tr key={user.userName}>
        <td className="uk-text-left">{user.email}</td>
        <td className="uk-text-left">
          {user.firstName} {user.lastName}
        </td>
        <td className="uk-text-left">{user.groups.join(", ")}</td>
        <td className="uk-text-left" />
        <td className="tm-actions">
          <div className="actions uk-flex">
            <button
              uk-icon="icon: trash"
              title="Delete ShortLink"
              className="uk-text-danger uk-icon"
              onClick={this.removeUser}
            />
          </div>
        </td>
      </tr>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    removeUser: (user: User) => {
      dispatch(removeUser(user));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(UserRow);
