import React from "react";
import { Auth } from "aws-amplify";
import ErrorBanner from "./errorBanner";
import styled from "styled-components";
import "../styles/authentication.scss";
import "../styles/application.scss";

interface IProps {
  authState?: string;
  onStateChange?(state: any, data: any): any;
}

interface IState {
  email?: string;
  password?: string;
  error?: string;
}

const Background = styled.div`
  background-image: linear-gradient(to bottom right, #409200, #c4ef2b);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export default class Landing extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: "",
      password: "",
      email: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async handleSubmit(event: any) {
    event.preventDefault();

    if (!this.state.email || !this.state.password) {
      this.setState({ ...this.state, error: "Enter email/password" });
      return;
    }

    try {
      const user = await Auth.signIn(this.state.email!, this.state.password!);
      this.setState({
        error: "",
        password: "",
        email: ""
      });
      const { onStateChange } = this.props;
      onStateChange!("signedIn", user);
    } catch (err) {
      this.setState({
        ...this.state,
        error: err.message
      });
    }
  }

  handleChange(event: any) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      ...this.state,
      [name]: value
    });
  }

  public render() {
    const { authState } = this.props;
    if (!["signIn", "signedOut", "signedUp"].includes(authState as string)) {
      return null;
    }

    let errorContent = null;
    if (this.state.error) {
      errorContent = (
        <ErrorBanner
          errorMessage={this.state.error}
          onClose={() => {
            this.setState({ ...this.state, error: undefined });
          }}
        />
      );
    }

    return (
      <Background className="authentication">
        <div className="uk-position-center">
          <h1 className="uk-light uk-text-center">&nbsp;Lemur</h1>
          <div className="uk-card uk-card-body uk-card-default uk-text-small uk-padding-remove-vertical uk-text-center uk-width-large">
            <div className="uk-section-xsmall">
              {errorContent}
              <div className="uk-flex uk-flex-column">
                <div className="uk-margin-top uk-padding-small uk-padding-remove-vertical">
                  <form onSubmit={this.handleSubmit}>
                    <input
                      className="uk-input input-top"
                      placeholder="Email"
                      type="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                    <input
                      className="uk-input input-bottom"
                      placeholder="Password"
                      type="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                    <input
                      type="submit"
                      name="commit"
                      value="Log In"
                      className="uk-button uk-button-primary uk-margin-small-top uk-width-1-1 auth-submit"
                      data-disable-with="Log In"
                    />
                  </form>
                </div>
                <div className="uk-margin-small-bottom">
                  <div className="auth-link">
                    <a href="/auth/forgot-password">Forgot your password?</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Background>
    );
  }
}
