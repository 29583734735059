import * as React from "react";
import { ShortLink } from "../types";
import { deleteShortLink, deleteShortLinkAlias } from "../redux/actions";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import styled from "styled-components";

interface Props {
  // domains: Domain[];
  shortLink: ShortLink;
  deleteShortLink: (shortLink: ShortLink) => void;
  deleteShortLinkAlias: (shortLink: ShortLink) => void;
  editShortLink: (shortLink: ShortLink) => void;
  onEditShortLink?: (shortLink: ShortLink) => void;
}

const Link = styled.a`
  color: #409200 !important;
`;

class ShortLinkRow extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.shortLinkURL = this.shortLinkURL.bind(this);
    this.shortLinkAliasURL = this.shortLinkAliasURL.bind(this);
    this.deleteShortLink = this.deleteShortLink.bind(this);
    this.deleteShortLinkAlias = this.deleteShortLinkAlias.bind(this);
    this.editShortLink = this.editShortLink.bind(this);
  }

  shortLinkForPath(path: number | string) {
    const baseRedirectURL = this.props.shortLink.fqdn;
    return `${baseRedirectURL}/${path}`;
  }

  shortLinkURL() {
    const tag = this.props.shortLink.tag;
    return this.shortLinkForPath(tag);
  }

  shortLinkAliasURL() {
    const shortLink = this.props.shortLink;
    const alias = shortLink.alias;
    let url = "";
    if (alias) {
      url = this.shortLinkForPath(alias);
    }
    return url;
  }

  copyToClipboard(value: string) {
    const copyElement = document.createElement("textarea");
    copyElement.value = value;
    copyElement.setAttribute("readonly", "");
    copyElement.style.position = "absolute";
    copyElement.style.left = "-9999px";
    document.body.appendChild(copyElement);
    copyElement.select();
    document.execCommand("copy");
    document.body.removeChild(copyElement);
  }

  editShortLink() {
    const shortLink = this.props.shortLink;

    if (this.props.onEditShortLink) {
      this.props.onEditShortLink(shortLink);
    }
    this.props.editShortLink(shortLink);
  }

  deleteShortLink() {
    if (window.confirm("Are you sure you wish to delete this ShortLink?")) {
      const shortLink = this.props.shortLink;
      this.props.deleteShortLink(shortLink);
    }
  }

  deleteShortLinkAlias() {
    const shortLink = this.props.shortLink;
    this.props.deleteShortLinkAlias(shortLink);
  }

  public render() {
    const shortLink = this.props.shortLink;
    let url = shortLink.url;
    if (shortLink.queryParameters) {
      url = `${url}?${shortLink.queryParameters}`;
    }

    return (
      <tr key={shortLink.id}>
        <td className="uk-text-left">
          <Link href={url}>{url}</Link>
        </td>
        <td className="uk-text-left">
          {this.shortLinkURL()}
          <button
            uk-icon="icon: copy"
            uk-tooltip="title: Copy Short Link"
            title="Copy Short Link"
            className="uk-icon uk-dark"
            style={{
              float: "left",
              marginRight: "15px",
              color: "#000"
            }}
            onClick={() => {
              this.copyToClipboard(this.shortLinkURL());
            }}
          />
        </td>
        <td className="uk-text-left">
          {this.shortLinkAliasURL()}
          <button
            uk-icon="icon: copy"
            uk-tooltip="Copy Alias"
            title="Copy Short Link"
            className="uk-icon"
            style={{
              float: "left",
              marginRight: "15px",
              color: "#000"
            }}
            hidden={this.props.shortLink.alias === ""}
            onClick={() => {
              this.copyToClipboard(this.shortLinkAliasURL());
            }}
          />
        </td>
        <td className="uk-text-left" />
        <td className="tm-actions">
          <div className="actions uk-flex">
            <button
              uk-icon="icon: pencil"
              uk-tooltip="Edit"
              title="Edit Short Link"
              className="uk-icon"
              style={{
                color: "#000"
              }}
              onClick={this.editShortLink}
            />
            <button
              uk-icon="icon: trash"
              uk-tooltip="Delete"
              title="Delete ShortLink"
              className="uk-text-danger uk-icon"
              onClick={this.deleteShortLink}
            />
          </div>
        </td>
      </tr>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    deleteShortLink: (shortLink: ShortLink) => {
      dispatch(deleteShortLink(shortLink));
    },
    deleteShortLinkAlias: (shortLink: ShortLink) => {
      dispatch(deleteShortLinkAlias(shortLink));
    },
    editShortLink: (shortLink: ShortLink) => {
      dispatch(push("/edit", shortLink));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ShortLinkRow);
