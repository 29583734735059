import React from "react";
import ReactMarkdown from "react-markdown";
import { BreadCrumbs } from ".";

interface State {
  markdownContent: string;
}

export default class Help extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      markdownContent: ""
    };
  }

  async componentDidMount() {
    const path = require("../help.md");
    const fileResult = await fetch(path);
    const content = await fileResult.text();
    this.setState({ markdownContent: content });
  }

  public render() {
    return (
      <div className="tm-main uk-section uk-section-default uk-padding-remove-top">
        <BreadCrumbs crumbs={[["Help"]]} />
        <div className="uk-container uk-position-relative tm-main">
          <h1 className="uk-heading-divider" style={{ marginTop: "40px" }}>
            Help
          </h1>
          <ReactMarkdown source={this.state.markdownContent} />
        </div>
      </div>
    );
  }
}
