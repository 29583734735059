import * as React from "react";
import { Auth } from "aws-amplify";
import { ResultType } from "../types";
import { Alert } from ".";
import _ from "lodash";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface Props {
  onError?: (err: Error) => void;
  showDashboard: () => void;
}

interface State {
  updateResult?: ResultType<string>;
  previousPassword: string;
  password: string;
  passwordCheck: string;
  passwordError?: Error;
  changePasswordEnabled: boolean;
}

class UpdateProfile extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.dismissAlert = this.dismissAlert.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onUpdatePassword = this.onUpdatePassword.bind(this);
    this.state = {
      changePasswordEnabled: false,
      previousPassword: "",
      password: "",
      passwordCheck: ""
    };
  }

  handleInputChange(event: any) {
    const target = event.currentTarget;
    const name = target.name;
    this.setState({ [name]: target.value } as State);

    const newState = { ...this.state, [name]: target.value };
    const changePasswordEnabled =
      !_.isEmpty(newState.previousPassword) &&
      !_.isEmpty(newState.password) &&
      !_.isEmpty(newState.passwordCheck);
    this.setState({ changePasswordEnabled });
  }

  presentError(error: Error) {
    this.setState({
      updateResult: Error(`☔️ ${error.message}`)
    });
  }

  dismissAlert() {
    this.setState({
      updateResult: undefined
    });
  }

  validatePasswords(
    previousPassword: string,
    password: string,
    passwordCheck: string
  ): Error | undefined {
    if (_.isEmpty(previousPassword)) {
      return Error("Please enter your current password");
    }

    if (_.isEmpty(password) && _.isEmpty(passwordCheck)) {
      if (password === passwordCheck) {
        // Currently Proboscis only requires that the password length is greater than 8 inclusive.
        if (password.length >= 8 && passwordCheck.length >= 8) {
          return undefined;
        } else {
          return Error("Password should be at least 8 characters");
        }
      } else {
        return Error("Passwords does not match");
      }
    } else if (_.isEmpty(password) || _.isEmpty(passwordCheck)) {
      return Error("Please confirm your password");
    }
  }

  async onUpdatePassword(event: any) {
    event.preventDefault();
    this.dismissAlert();

    const previousPassword = this.state.previousPassword!;
    const password = this.state.password!;
    const passwordConfirmation = this.state.passwordCheck!;
    const error = this.validatePasswords(
      previousPassword,
      password,
      passwordConfirmation
    );

    if (error) {
      return this.presentError(error);
    }

    try {
      let user = await Auth.currentAuthenticatedUser();
      let result = await Auth.changePassword(user, previousPassword, password);

      if (result === "SUCCESS") {
        this.setState({
          updateResult: result
        });
      } else {
        console.error(result);
        this.presentError(Error("Something went wrong."));
      }
    } catch (err) {
      console.error(err);
      this.presentError(Error("Something went wrong."));
      if (this.props.onError) this.props.onError(err);
    }
  }

  public render() {
    return (
      <div className="uk-container">
        <h3 className="uk-heading-divider">
          <div className="uk-flex uk-flex-between uk-flex-middle">
            <div>Change Password</div>
          </div>
        </h3>
        <form onSubmit={this.onUpdatePassword} className="uk-form-stacked">
          <div className="uk-margin">
            <label className="uk-form-label">
              Current Password
              <input
                className={
                  this.state.passwordError
                    ? "uk-input uk-form-danger"
                    : "uk-input"
                }
                type="password"
                autoComplete="new-password"
                name="previousPassword"
                onChange={this.handleInputChange}
              />
            </label>
          </div>
          <div className="uk-margin">
            <label className="uk-form-label">
              Password
              <input
                className={
                  this.state.passwordError
                    ? "uk-input uk-form-danger"
                    : "uk-input"
                }
                type="password"
                autoComplete="new-password"
                name="password"
                onChange={this.handleInputChange}
              />
            </label>
          </div>
          <div className="uk-margin">
            <label className="uk-form-label">
              Confirm Password
              <input
                className={
                  this.state.passwordError
                    ? "uk-input uk-form-danger"
                    : "uk-input"
                }
                type="password"
                autoComplete="new-password"
                name="passwordCheck"
                onChange={this.handleInputChange}
              />
            </label>
          </div>
          <hr />
          <Alert
            resultType={this.state.updateResult}
            successMessage="🎉 Your password has been updated"
            onClose={this.dismissAlert}
          />

          <input
            type="submit"
            value="Change Password"
            className="uk-button uk-button-primary uk-float-right"
            onClick={this.onUpdatePassword}
            disabled={!this.state.changePasswordEnabled}
          />
          <input
            value="Cancel"
            className="uk-button uk-button-default uk-float-left uk-margin-large-bottom"
            onClick={() => this.props.showDashboard()}
          />
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    showDashboard: () => {
      dispatch(push("/"));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(UpdateProfile);
