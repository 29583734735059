import {
  Actions,
  ShortLink,
  DefaultAction,
  StoreState,
  User,
  UpdateShortLinkURLOperation
} from "../types.d";
import { API, graphqlOperation } from "aws-amplify";
import { listShortLinks } from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { push } from "connected-react-router";

export function dismissError() {
  return {
    type: Actions.ErrorDismissed
  };
}

export function fetchDomains() {
  return async (dispatch: any) => {
    try {
      let domainsData = (await API.graphql(
        graphqlOperation(queries.listDomains)
      )) as any;
      const domains = domainsData.data.listDomains;
      return dispatch({
        type: Actions.PullDomains,
        payload: domains
      });
    } catch (err) {
      dispatch(pullShortLinksError(err));
    }
  };
}

export function pullUsers() {
  return async (dispatch: any) => {
    try {
      let usersData = (await API.graphql(
        graphqlOperation(queries.listUsers)
      )) as any;

      const users = usersData.data.listUsers;
      return dispatch({
        type: Actions.PullUsers,
        payload: users
      });
    } catch (err) {
      dispatch(pullShortLinksError(err));
    }
  };
}

export function createUser(email: string) {
  return async (dispatch: any) => {
    try {
      await API.graphql(
        graphqlOperation(mutations.addUser, {
          email: email
        })
      );

      dispatch(pullUsers());
    } catch (err) {
      dispatch(pullShortLinksError(err));
    }
  };
}

export function updateShortLinkURLs(operations: UpdateShortLinkURLOperation[]) {
  return async (dispatch: any) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.updateShortLinkURLs, {
          operations: operations
        })
      )) as any;

      const results = result.data.updateShortLinkURLs.map((result: any) => {
        const operation = operations.find(
          (operation: UpdateShortLinkURLOperation) => {
            return operation.shortLinkURL === result.shortLinkURL;
          }
        )!;
        result.redirectURL = operation.redirectURL;
        return result;
      });

      return dispatch({
        type: Actions.UpdateShortLinkURLs,
        payload: results
      });
    } catch (err) {
      dispatch(pullShortLinksError(err));
    }
  };
}

export function removeUser(user: User) {
  return async (dispatch: any) => {
    try {
      await API.graphql(
        graphqlOperation(mutations.removeUser, {
          userName: user.userName
        })
      );

      dispatch(pullUsers());
    } catch (err) {
      dispatch(pullShortLinksError(err));
    }
  };
}

export function searchShortLinks(query: string) {
  return async (dispatch: any) => {
    try {
      let shortLinkData = (await API.graphql(
        graphqlOperation(queries.searchShortLinks, {
          query: query
        })
      )) as any;

      let shortLinks = [];
      if (shortLinkData.data.searchShortLinks) {
        const shortLinksData = shortLinkData.data.searchShortLinks;
        shortLinks = shortLinksData.map((shortLinkData: any) => {
          return new ShortLink(shortLinkData);
        });
      }

      return dispatch({
        type: Actions.SearchShortLinks,
        payload: shortLinks
      });
    } catch (err) {
      dispatch(pullShortLinksError(err));
    }
  };
}

export function deleteShortLink(shortLink: ShortLink) {
  return (dispatch: any) => {
    return dispatch({
      type: Actions.DeleteShortLink,
      payload: async () => {
        try {
          await API.graphql(
            graphqlOperation(mutations.deleteShortLink, {
              id: shortLink.id
            })
          );

          dispatch({
            type: Actions.DeleteShortLink,
            payload: {
              shortLink: shortLink
            }
          });
          dispatch(push("/"));
          dispatch(pullShortLinks());
        } catch (err) {
          dispatch(pullShortLinksError(err));
        }
      }
    });
  };
}

export function updateShortLink(
  id: number,
  url: string,
  alias: string,
  domainId: number
) {
  return async (dispatch: any) => {
    try {
      let shortLinkData = (await API.graphql(
        graphqlOperation(mutations.updateShortLink, {
          id,
          url,
          alias,
          domainId
        })
      )) as any;

      return dispatch({
        type: Actions.UpdateShortLinkResult,
        payload: new ShortLink(shortLinkData.data.updateShortLink)
      });
    } catch (err) {
      debugger;
      dispatch({
        type: Actions.HandleError,
        payload: new Error(err.errors[0].message) // Return first error
      });
      dispatch({
        type: Actions.UpdateShortLinkResult,
        payload: new Error("☔️ Something went wrong")
      });
    }
  };
}

export function deleteShortLinkAlias(shortLink: ShortLink) {
  return (dispatch: any) => {
    return dispatch({
      type: Actions.DeleteShortLink,
      payload: async () => {
        try {
          await API.graphql(
            graphqlOperation(mutations.updateShortLink, {
              input: { id: shortLink.id, alias: "" }
            })
          );
          dispatch(pullShortLinks());
        } catch (err) {
          dispatch(pullShortLinksError(err));
        }
      }
    });
  };
}

export function pushShortLink(
  redirectURL: string,
  domainId: number,
  alias?: string
) {
  return (dispatch: any) => {
    return dispatch({
      type: Actions.PushShortLink,
      payload: async () => {
        try {
          await API.graphql(
            graphqlOperation(mutations.createShortLink, {
              url: redirectURL,
              alias: alias,
              domainId: domainId
            })
          );
          dispatch(pullShortLinks());
        } catch (err) {
          dispatch(pullShortLinksError(err));
        }
      }
    });
  };
}

export function pullShortLinks(page: number = 1) {
  return (dispatch: any) => {
    return dispatch({
      type: Actions.PullShortLinks,
      payload: async () => {
        try {
          let shortLinksResponse = await API.graphql(
            graphqlOperation(listShortLinks, {
              limit: 25,
              page: page
            })
          );
          dispatch(pullShortLinksCompleted(shortLinksResponse, page));
        } catch (err) {
          dispatch(pullShortLinksError(err));
        }
      }
    });
  };
}

export function pullNextShortLinks() {
  return (dispatch: any, getState: any) => {
    const { state } = getState() as StoreState;
    const shortLinkNextPage = state.shortLinks.page;
    dispatch(pullShortLinks(shortLinkNextPage));
  };
}

export function pullShortLinksCompleted(
  response: any,
  page: number
): DefaultAction {
  const shortLinksData = response.data.listShortLinks;
  if (response.data) {
    const shortLinks = shortLinksData.map((shortLinkData: any) => {
      return new ShortLink(shortLinkData);
    });
    return {
      type: Actions.PullShortLinksCompleted,
      payload: {
        shortLinks: shortLinks,
        page: page
      }
    };
  } else {
    return pullShortLinksError("Invalid graph response");
  }
}

export function pullShortLinksError(err: any): DefaultAction {
  console.error(err);
  return {
    type: Actions.HandleError,
    payload: err
  };
}

export function exportShortLinksSearch(query: string) {
  return (dispatch: any) => {
    return dispatch({
      type: Actions.ExportShortLinksSearch,
      payload: async () => {
        try {
          await API.graphql(
            graphqlOperation(mutations.exportShortLinksDryRun, {
              query: query
            })
          );
          dispatch(pullShortLinks());
        } catch (err) {
          dispatch(pullShortLinksError(err));
        }
      }
    });
  };
}

