// tslint:disable
// this is an auto generated file. This will be overwritten

export const createShortLink = `mutation CreateShortLink($url: AWSURL!, $alias: String, $domainId: Int!) {
  createShortLink(url: $url, alias: $alias, domainId: $domainId) {
    id
    url
    createdAt
    updatedAt
    alias
    queryParameters
    tag
  }
}
`;
export const updateShortLinkURLs = `mutation UpdateShortLinkURLs($operations: [UpdateShortLinkURL]!) {
  updateShortLinkURLs(operations: $operations) {
    shortLinkURL
    success
    error
  }
}
`;
export const updateShortLink = `mutation UpdateShortLink($id: Int!, $url: AWSURL!, $alias: String, $domainId: Int!) {
  updateShortLink(id: $id, url: $url, alias: $alias, domainId: $domainId) {
    id
    url
    createdAt
    updatedAt
    alias
    queryParameters
    tag
  }
}
`;
export const deleteShortLink = `mutation DeleteShortLink($id: Int!) {
  deleteShortLink(id: $id) {
    id
    url
    createdAt
    updatedAt
    alias
    queryParameters
    tag
  }
}
`;
export const addUser = `mutation AddUser($email: AWSEmail!) {
  addUser(email: $email) {
    userName
    firstName
    lastName
    email
    groups
  }
}
`;
export const removeUser = `mutation RemoveUser($userName: ID!) {
  removeUser(userName: $userName) {
    userName
    firstName
    lastName
    email
    groups
  }
}
`;
export const exportShortLinksDryRun = `mutation ExportShortLinksDryRun($query: String!) {
  exportShortLinksDryRun(query: $query) {
    count
    head {
      id
      url
      createdAt
      updatedAt
      alias
      queryParameters
      tag
      domainId
    }
    tail {
      id
      url
      createdAt
      updatedAt
      alias
      queryParameters
      tag
      domainId
    }
  }
}
`;
export const exportShortLinks = `mutation ExportShortLinks($query: String!) {
  exportShortLinks(query: $query)
}
`;
export const batchOperation = `mutation BatchOperation($operations: [BatchOperationInput], $dryRun: Boolean) {
  batchOperation(operations: $operations, dryRun: $dryRun) {
    operations {
      id
      shortLink {
        id
        url
        createdAt
        updatedAt
        alias
        queryParameters
        tag
      }
      redirectURL
      shortLinkURL
      applied
      error
      command
    }
    exportCSV
  }
}
`;
