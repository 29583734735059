import {
  Action,
  Actions,
  AppState,
  ShortLink,
  ShortLinkState,
  Domain
} from "../types.d";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import _ from "lodash";

function reduce(
  state: AppState = {
    shortLinks: {
      startIndex: 0,
      page: 0,
      rawShortLinks: []
    },
    foundShortLinks: [],
    users: [],
    domains: [],
    updateShortLinkURLsResult: []
  },
  action: Action<any>
): AppState {
  switch (action.type) {
    case Actions.PullShortLinksCompleted:
      let rawShortLinks = action.payload.shortLinks.sort(
        (a: ShortLink, b: ShortLink) => {
          return b.updatedAt.getTime() - a.updatedAt.getTime();
        }
      );
      let page = action.payload.page;
      let shortLinks = { ...state.shortLinks, rawShortLinks, page };
      return { ...state, shortLinks };
    case Actions.DeleteShortLink:
      return state;
    case Actions.UpdateShortLinkResult:
      if (action.payload instanceof Error) {
        let error = action.payload;
        return { ...state, lastResult: error };
      } else {
        let s = updateShortLink(state.shortLinks, action.payload);
        return {
          ...state,
          shortLinks: s,
          lastResult: action.payload
        };
      }
    case Actions.HandleError:
      console.error(action.payload);
      return state;
    case Actions.ErrorDismissed:
      return { ...state, lastResult: undefined };
    case Actions.SearchShortLinks:
      return { ...state, foundShortLinks: action.payload };
    case Actions.PullUsers:
      return { ...state, users: action.payload };
    case Actions.PullDomains:
      let domains = action.payload.sort((a: Domain, b: Domain) => {
        return b.id - a.id;
      });
      return { ...state, domains };
    case Actions.UpdateShortLinkURLs:
      return { ...state, updateShortLinkURLsResult: action.payload };
    default:
      return state;
  }
}

function updateShortLink(state: ShortLinkState, shortLink: ShortLink) {
  let rawShortLinks = state.rawShortLinks!;
  let index = _.findIndex(rawShortLinks, {
    id: shortLink.id
  });

  if (index >= 0) {
    rawShortLinks.splice(index, 1, shortLink);
  }
  return { ...state, rawShortLinks };
}

// function deleteShortLink(state: ShortLinkState, shortLink: ShortLink) {
//   let rawShortLinks = state.rawShortLinks;

//   if (rawShortLinks) {
//     rawShortLinks = _.remove(rawShortLinks, shortLink);
//   }

//   return { ...state, rawShortLinks };
// }

export default (history: History) =>
  combineReducers({
    router: connectRouter(history),
    state: reduce
  });
