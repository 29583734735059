import * as React from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {URLInput} from '..';
import {pushShortLink, fetchDomains} from '../../redux/actions';
import validator from 'validator';
import {StoreState, Domain} from '../../types';
import BreadCrumbs from '../breadCrumbs';

interface Props {
  pushShortLink: (
    redirectURL: string,
    domainId: number,
    alias?: string,
  ) => void;
  redirectToRoute: (route: string) => void;
  pullDomains: () => any;
  domains: Domain[];
}

interface State {
  domainId?: number;
  redirectURL: string;
  alias: string;
  baseRedirectURL: string;
  isAddDisabled: boolean;
  destinationURLError: boolean;
}

class ShortLinksNew extends React.Component<Props, State> {
  componentDidMount() {
    this.props.pullDomains();
  }

  componentDidUpdate() {
    if (this.props.domains.length) {
      const domainFQDN = this.props.domains[0]!.fqdn;
      const urlString = `https://${domainFQDN}/`;
      if (this.state.baseRedirectURL === urlString) {
        return;
      }
      this.setState({baseRedirectURL: urlString});
    }
  }

  constructor(props: any) {
    super(props);

    this.state = {
      redirectURL: '',
      alias: '',
      isAddDisabled: true,
      destinationURLError: false,
      baseRedirectURL: '',
    };

    this.handleDomainChange = this.handleDomainChange.bind(this);
    this.handleURLChange = this.handleURLChange.bind(this);
    this.handleTagChange = this.handleTagChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getInfo() {
    if (this.props.domains.length) {
      const domainFQDN = this.props.domains[0]!.fqdn;
      this.setState({baseRedirectURL: `https://${domainFQDN}/`});
    }
  }

  handleURLChange(_: string, redirectURL: string, isValid: boolean) {
    this.setState({
      destinationURLError: isValid,
      redirectURL: redirectURL,
    });
    this.validateInputs();
  }

  handleDomainChange(event: any) {
    let domainId = parseInt(event.target.value);
    let domainFQDN = this.props.domains.find((d: Domain) => d.id === domainId)!
      .fqdn;
    this.setState({
      domainId,
      baseRedirectURL: `https://${domainFQDN}/`,
    });
  }

  handleTagChange(_: string, alias: string) {
    this.setState({
      ...this.state,
      alias,
    });
    this.validateInputs();
  }

  cleanUp() {
    this.setState({
      redirectURL: '',
      alias: '',
      isAddDisabled: true,
      destinationURLError: false,
    });
  }

  validateInputs() {
    let redirectURL = `https://${this.state.redirectURL}`;
    let tag = `${this.state.baseRedirectURL}/${this.state.alias}`;
    let addDisabled = true;
    if (validator.isURL(redirectURL) && validator.isURL(tag)) {
      addDisabled = false;
    }
    this.setState({
      isAddDisabled: addDisabled,
    });
  }

  handleSubmit(event: any) {
    event.preventDefault();
    this.cleanUp();

    const domainId = this.state.domainId!;

    this.props.pushShortLink(
      `https://${this.state.redirectURL}`,
      domainId,
      this.state.alias,
    );

    this.props.redirectToRoute('/short-links/');
  }

  public render() {
    if (this.props.domains.length === 0) {
      return <></>;
    }

    if (!this.state.domainId) {
      this.setState({domainId: this.props.domains[0].id});
    }

    const domainOptions = this.props.domains.map((d: Domain) => {
      return (
        <option value={d.id} key={d.id}>
          {d.fqdn}
        </option>
      );
    });

    return (
      <>
        <div className="tm-main uk-section uk-section-default uk-padding-remove-top uk-padding-remove-bottom">
          <BreadCrumbs crumbs={[['Short Links', '/short-links'], ['New']]} />
          <div className="uk-container uk-position-relative tm-main">
            <h1 className="uk-heading-divider">New Short Link</h1>
          </div>
        </div>
        <form
          onSubmit={this.handleSubmit}
          className="uk-container uk-position-relative tm-main">
          <div className="uk-form-label uk-margin-top uk-width-100">
            <div className="uk-margin-bottom">
              <label className="uk-form-label uk-margin-top">
                Destination URL
                <URLInput
                  accessory="https://"
                  url={this.state.redirectURL}
                  placeholder="example.com"
                  onChange={this.handleURLChange}
                />
              </label>
            </div>

            <div className="uk-margin-bottom">
              <label className="uk-form-label uk-width-100">
                Domain
                <select
                  className="uk-select"
                  value={this.state.domainId}
                  onChange={this.handleDomainChange}>
                  {domainOptions}
                </select>
              </label>
            </div>

            <div className="uk-margin-bottom">
              <label className="uk-form-label uk-width-100">
                Vanity Alias
                <URLInput
                  accessory={this.state.baseRedirectURL}
                  url={this.state.alias}
                  placeholder="alias"
                  onChange={this.handleTagChange}
                />
              </label>
              <div>
                <hr />
                <div className="uk-flex uk-flex-between">
                  <button
                    className="uk-button uk-button-primary tm-button-new"
                    uk-tooltip="Invite User"
                    title=""
                    onClick={this.handleSubmit}
                    disabled={this.state.isAddDisabled}>
                    Create Short Link
                  </button>

                  <button
                    className="uk-button uk-button-primary tm-button-new"
                    uk-tooltip="Invite User"
                    title=""
                    onClick={() => {
                      this.props.redirectToRoute('/short-links/');
                    }}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = ({state}: StoreState) => {
  return {
    domains: state.domains,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    pushShortLink: (
      redirectURL: string,
      domainId: number,
      vanityAlias?: string,
    ) => {
      dispatch(pushShortLink(redirectURL, domainId, vanityAlias));
    },
    pullDomains: () => {
      dispatch(fetchDomains());
    },
    redirectToRoute: (route: string) => {
      dispatch(push(route));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShortLinksNew);
