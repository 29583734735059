import * as React from "react";
import { connect } from "react-redux";
import { pullUsers } from "../redux/actions";
import { User, StoreState } from "../types";
import AddUser from "./addUser";
import UserRow from "./userRow";
import BreadCrumbs from "./breadCrumbs";

interface IShortLinksProps {
  users: User[];
  pullUsers: () => void;
}

class Dashboard extends React.Component<IShortLinksProps, any> {
  componentWillMount() {
    this.props.pullUsers();
  }

  public renderLoading() {
    return <div className="uk-spinner" />;
  }

  public render() {
    let userRows = this.props.users.flatMap(user => {
      return <UserRow user={user} key={user.userName} />;
    });

    return (
      <div className="tm-main uk-section uk-section-default uk-padding-remove-top">
        <BreadCrumbs crumbs={[["Users"]]} />
        <div className="uk-container uk-position-relative tm-main">
          <h1 className="uk-heading-divider" style={{ marginTop: "40px" }}>
            Users
          </h1>
          <AddUser />
          <div className="uk-flex uk-flex-between uk-flex-middle uk-margin-top">
            <div className="pagination-info">
              Displaying <b>{this.props.users.length}</b> users
            </div>
          </div>

          <table className="uk-table uk-table-divider uk-table-small">
            <thead>
              <tr>
                <th>email</th>
                <th>name</th>
                <th>role</th>
                <th />
              </tr>
            </thead>
            <tbody>{userRows}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ state }: StoreState) => {
  return {
    users: state.users
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    pullUsers: () => {
      dispatch(pullUsers());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
