import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import BreadCrumbs from "../breadCrumbs";
import { URLInput } from "..";
import { API, graphqlOperation } from "aws-amplify";
import { ShortLink, StoreState, Domain } from "../../types.d";
import { fetchDomains } from "../../redux/actions";
import ShortLinkRow from "../shortLinkRow";
import * as mutations from "../../graphql/mutations";
import * as Sentry from "@sentry/browser";
import { CSVLink } from "react-csv";

interface Props {
  shortLinks: ShortLink[];
  domains: Domain[];
  pullDomains: () => void;
  redirectToRoute: (route: string) => void;
}

interface State {
  searchPattern: string;
  searchResult?: SearchResult;
  error: boolean;
  searchTerm?: string;
  progress: number;
  whichStage: string;
  exportedCSV: string;
  loading: boolean;
}

interface SearchResult {
  count: number;
  head: ShortLink[];
  tail: ShortLink[];
}

class Export extends React.Component<any, State, Props> {
  constructor(props: any) {
    super(props);
    this.state = {
      progress: 0,
      searchPattern: "",
      error: false,
      whichStage: "search",
      exportedCSV: "",
      loading: false
    };
    this.handleSearchPatternChange = this.handleSearchPatternChange.bind(this);
    this.search = this.search.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleViewResultsClick = this.handleViewResultsClick.bind(this);
    this.handleGoToSearchClick = this.handleGoToSearchClick.bind(this);
    this.handleGoToExportClick = this.handleGoToExportClick.bind(this);
    this.handleExportClick = this.handleExportClick.bind(this);
  }

  componentWillMount() {
    this.props.pullDomains();
  }

  data: any;

  headers = [
    { label: "Alias", key: "alias" },
    { label: "Redirect URL", key: "url" },
    { label: "Short Link", key: "shortLink" },
    { label: "ID", key: "id" },
    { label: "Domain ID", key: "domainId" },
    { label: "Created At", key: "createdAt" },
    { label: "Updated At", key: "updatedAt" }
  ];

  async search() {
    try {
      this.setState({ loading: true });
      const searchPattern = this.state.searchPattern;
      const response = (await API.graphql(
        graphqlOperation(mutations.exportShortLinksDryRun, {
          query: searchPattern
        })
      )) as any;

      const searchResult = response.data.exportShortLinksDryRun as SearchResult;
      this.setState({ searchTerm: searchPattern });
      this.setState({ searchResult });
      this.setState({ loading: false });
    } catch (err) {
      Sentry.captureException(err);
      this.setState({ error: true });
      console.error(err);
    }
  }

  async prepareExport() {
    try {
      const searchPattern = this.state.searchPattern;
      const response = (await API.graphql(
        graphqlOperation(mutations.exportShortLinks, {
          query: searchPattern
        })
      )) as any;

      const exportedCSV = response.data.exportShortLinks as string;
      debugger;
      this.setState({ exportedCSV });
    } catch (err) {
      Sentry.captureException(err);
      this.setState({ error: true });
      console.error(err);
    }
  }

  handleSearchPatternChange(_: string, searchPattern: string) {
    this.setState({ searchPattern });
  }

  handleFormSubmit(e: any) {
    e.preventDefault();
    this.search();
  }

  handleViewResultsClick() {
    this.setState({ progress: 1 });
    this.setState({ whichStage: "preview" });
  }

  handleGoToSearchClick() {
    this.setState({ progress: 0 });
    this.setState({ whichStage: "search" });
  }

  async handleGoToExportClick() {
    await this.prepareExport();
    this.setState({ progress: 2 });
    this.setState({ whichStage: "export" });
  }

  handleExportClick() {
    this.setState({ progress: 3 });
    this.setState({ whichStage: "done" });
  }

  setData(link: any) {
    if (link) {
      let result = [];
      for (let i = 0; i < link.length; i++) {
        result.push({
          alias: `http://${link[i].fqdn}/${link[i].alias}`,
          url: link[i].url,
          shortLink: `http://${link[i].fqdn}/${link[i].tag}`,
          id: link[i].id,
          domainId: link[i].domainId,
          createdAt: link[i].createdAt,
          updatedAt: link[i].updatedAt
        });
      }
      return result;
    } else {
      return null;
    }
  }

  shortLinks: any;

  getFqdn() {
    if (this.state.searchResult) {
      this.shortLinks = this.state.searchResult.head;
      this.data = this.setData(this.shortLinks);
    }
    let domains = this.props.domains;
    if (this.shortLinks && domains.length > 0) {
      this.shortLinks = this.shortLinks.map((s: ShortLink) => {
        const domain = domains.find((d: Domain) => d.id === s.domainId);
        if (domain) {
          s.fqdn = domain.fqdn;
        }
      });
    }
    return this.shortLinks;
  }

  resultsContent: any;
  resultsButton: any;
  exportNum = 0;

  renderResultsContent() {
    if (this.state.searchResult && this.state.searchResult.count === 0) {
      this.resultsContent = (
        <div className="uk-text-center uk-margin-medium-top uk-text-large">
          No results found for{" "}
          <span className="uk-text-bold uk-text-primary">
            {this.state.searchTerm}
          </span>
        </div>
      );
    }
    if (this.state.loading === true) {
      this.resultsContent = (
        <div className="uk-flex uk-flex-center">
          <div uk-spinner="ratio: 2" />
        </div>
      );
    }
    if (this.state.searchResult && this.state.searchResult.count > 0) {
      this.resultsContent = (
        <div className="uk-text-center uk-text-large">
          Your search for
          <span className="uk-text-bold uk-text-primary">{` ${
            this.state.searchTerm
          } `}</span>
          returned
          <span className="uk-text-bold uk-text-primary">{` ${
            this.state.searchResult.count
          }`}</span>{" "}
          results
        </div>
      );

      this.resultsButton = (
        <button
          className="uk-align-center uk-button uk-button-primary uk-margin-top"
          onClick={this.handleViewResultsClick}
        >
          View Results
        </button>
      );

      this.exportNum = this.state.searchResult.count;
    }
  }

  previewRows: any;

  renderPreview() {
    if (
      this.state.searchResult &&
      this.state.searchResult.count <= 6 &&
      this.state.searchResult.count > 0
    ) {
      let rows = this.state.searchResult.head.map((shortLink: any) => {
        return <ShortLinkRow shortLink={shortLink} key={shortLink.id} />;
      });
      this.previewRows = (
        <>
          {this.resultsContent}
          <table className="uk-table uk-table-divider uk-table-small">
            <thead>All Results</thead>
            <thead>
              <tr>
                <th>url</th>
                <th>short link</th>
                <th>alias</th>
                <th />
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </>
      );
    }

    if (this.state.searchResult && this.state.searchResult.count > 6) {
      const allRows = this.state.searchResult.head;
      this.previewRows = (
        <>
          {this.resultsContent}
          <table className="uk-table uk-table-divider uk-table-small">
            <thead>
              <tr>
                <th />
                <th>url</th>
                <th>short link</th>
                <th>alias</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>{allRows[0].url}</td>
                <td>{`https://${allRows[0].fqdn}/${allRows[0].tag}`}</td>
                <td>{`https://${allRows[0].fqdn}/${allRows[0].alias}`}</td>
              </tr>
              <tr>
                <td>2</td>
                <td>{allRows[1].url}</td>
                <td>{`https://${allRows[1].fqdn}/${allRows[1].tag}`}</td>
                <td>{`https://${allRows[1].fqdn}/${allRows[1].alias}`}</td>
              </tr>
              <tr>
                <td>3</td>
                <td>{allRows[2].url}</td>
                <td>{`https://${allRows[2].fqdn}/${allRows[2].tag}`}</td>
                <td>{`https://${allRows[2].fqdn}/${allRows[2].alias}`}</td>
              </tr>
              <tr>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
              </tr>
              <tr>
                <td>{allRows.length}</td>
                <td>{allRows[allRows.length - 1].url}</td>
                <td>{`https://${allRows[allRows.length - 1].fqdn}/${
                  allRows[allRows.length - 1].tag
                }`}</td>
                <td>{`https://${allRows[allRows.length - 1].fqdn}/${
                  allRows[allRows.length - 1].alias
                }`}</td>
              </tr>
            </tbody>
          </table>
        </>
      );
    }
  }

  wizardSection: any;

  renderWizardSection() {
    if (this.state.whichStage === "search") {
      this.wizardSection = (
        <>
          <div className="uk-container uk-position-relative tm-main">
            <div>
              <h2
                className="uk-text-large uk-text-primary"
                style={{
                  fontWeight: 400,
                  fontSize: "40px",
                  width: "50px",
                  display: "inline-block"
                }}
              >
                1
              </h2>
              <h3
                className="uk-margin-right"
                style={{ marginBottom: "5px", display: "inline-block" }}
              >
                Search for an Alias
              </h3>
              <div className="uk-flex uk-margin-medium-bottom">
                <div className="uk-width-expand">
                  <form onSubmit={this.handleFormSubmit}>
                    <URLInput
                      accessory="https://a.domain.com/"
                      url={this.state.searchPattern}
                      placeholder="alias"
                      onChange={this.handleSearchPatternChange}
                    />
                  </form>
                </div>
                <div className="uk-width-auto">
                  <button
                    className="uk-icon-button uk-button-primary tm-button-new uk-icon uk-margin-left"
                    uk-icon="search"
                    uk-tooltip="Search"
                    title=""
                    onClick={this.search}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>{this.resultsContent}</div>
          <div>{this.resultsButton}</div>
        </>
      );
    }

    if (this.state.whichStage === "preview") {
      this.wizardSection = (
        <>
          <div className="uk-container uk-position-relative tm-main">
            <div>
              <h2
                className="uk-text-large uk-text-primary"
                style={{
                  fontWeight: 400,
                  fontSize: "40px",
                  width: "50px",
                  display: "inline-block"
                }}
              >
                2
              </h2>
              <h3
                className="uk-margin-right"
                style={{ marginBottom: "5px", display: "inline-block" }}
              >
                Preview Export
              </h3>
              <div>{this.previewRows}</div>
              <div className="uk-button-group uk-flex uk-flex-center">
                <button
                  className="uk-button uk-button-default uk-margin-right"
                  onClick={this.handleGoToSearchClick}
                >
                  Back to Search
                </button>
                <button
                  className="uk-button uk-button-primary uk-margin-left"
                  onClick={this.handleGoToExportClick}
                >
                  Go to Export
                </button>
              </div>
            </div>
          </div>
        </>
      );
    }

    if (this.state.whichStage === "export") {
      this.wizardSection = (
        <>
          <div className="uk-container uk-position-relative tm-main">
            <div>
              <h2
                className="uk-text-large uk-text-primary"
                style={{
                  fontWeight: 400,
                  fontSize: "40px",
                  width: "50px",
                  display: "inline-block"
                }}
              >
                3
              </h2>
              <h3
                className="uk-margin-right"
                style={{ marginBottom: "5px", display: "inline-block" }}
              >
                Export CSV File
              </h3>
              <div className="uk-text-center uk-margin-medium-top uk-text-large">
                Export
                <span className="uk-text-bold uk-text-primary">
                  {" "}
                  {this.exportNum}
                </span>{" "}
                Links
              </div>
              <div className="uk-button-group uk-flex uk-flex-center">
                <button
                  className="uk-button uk-button-default uk-margin-right uk-margin-top"
                  onClick={this.handleViewResultsClick}
                >
                  Back to Preview
                </button>
                <button
                  className="uk-button uk-button-primary uk-margin-left uk-margin-top"
                  onClick={this.handleExportClick}
                >
                  <CSVLink data={this.state.exportedCSV}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <span uk-icon="download" />
                    Export as .CSV File
                  </CSVLink>
                </button>
              </div>
            </div>
          </div>
        </>
      );
    }

    if (this.state.whichStage === "done") {
      this.wizardSection = (
        <>
          <div className="uk-flex uk-flex-center uk-margin-top">
            <h3>CSV Downloaded!</h3>
          </div>
          <div className="uk-button-group uk-flex uk-flex-center">
            <button
              className="uk-button uk-button-primary uk-margin-right"
              onClick={this.handleGoToSearchClick}
            >
              Back to Search
            </button>
            <button
              className="uk-button uk-button-primary uk-margin-left"
              onClick={() => {
                this.props.redirectToRoute("/short-links/url/edit");
              }}
            >
              Go To Bulk Edit
            </button>
          </div>
        </>
      );
    }
  }

  render() {
    this.getFqdn();
    this.renderResultsContent();
    this.renderPreview();
    this.renderWizardSection();

    return (
      <>
        <div className="tm-main uk-section uk-section-default uk-padding-remove-top uk-padding-remove-bottom">
          <BreadCrumbs crumbs={[["Export"]]} />
          <div className="uk-container uk-position-relative tm-normal">
            <h1 className="uk-heading-divider">Export Short Links</h1>
            <div
              className="uk-alert-danger"
              uk-alert=""
              hidden={!this.state.error}
            >
              <button className="uk-alert-close" uk-close="" />
              <p>
                <span aria-label="celebrate" role="img">
                  An unexpected error occurred. The development team has been
                  notified and will get back to you soon.
                </span>
              </p>
            </div>
          </div>
          <div>{this.wizardSection}</div>
          <progress
            className="uk-progress uk-width-1-2 uk-align-center uk-margin-large-bottom"
            value={this.state.progress}
            max="3"
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ state }: StoreState, { router }: any) => {
  return {
    domains: state.domains
    // location: router.location.pathname
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    pullDomains: () => {
      dispatch(fetchDomains());
    },
    redirectToRoute: (route: string) => {
      dispatch(push(route));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Export);
