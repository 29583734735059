import * as React from "react";
import { UpdateProfile, ChangePassword } from ".";

interface Props {}

export default class Settings extends React.Component<Props, any> {
  public render() {
    return (
      <div className="tm-main uk-section uk-section-default">
        <div className="uk-container uk-position-relative tm-main">
          <h2 className="uk-heading-divider">
            <div className="uk-flex uk-flex-between">
              <div>Settings</div>
            </div>
          </h2>
          <UpdateProfile />
          <ChangePassword />
        </div>
      </div>
    );
  }
}
