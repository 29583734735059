import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import promiseMiddleware from "redux-promise-middleware";
import thunk from "redux-thunk";
import App from "./App";
import Landing from "./components/landing";
import { StoreState } from "./types";
import * as serviceWorker from "./serviceWorker";
import createRootReducer from "./redux/reducers";
import Amplify from "aws-amplify";
import { Greetings, SignIn, Authenticator } from "aws-amplify-react";
import config from "react-global-configuration";
import { createBrowserHistory } from "history";
import { ConnectedRouter } from "connected-react-router";
import { Unauthorized, ErrorBoundary } from "./components";
import * as Sentry from "@sentry/browser";

const environment = process.env.NODE_ENV as string;
if (environment === "staging" || environment === "production") {
  Sentry.init({
    dsn: "https://527f539ae2314ca1b4de69088480a050@sentry.io/1432410",
    environment: process.env.NODE_ENV,
    release: `pineapple-lemur-${process.env.REACT_APP_VERSION}`
  });
}

const userPoolId = process.env.REACT_APP_USER_POOL_ID;
const userPoolWebClientId = process.env.REACT_APP_USER_POOL_CLIENT_ID;
const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;

const composeEnhancer: typeof compose =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const history = createBrowserHistory();
let middleware = [routerMiddleware(history), promiseMiddleware, thunk];
const store = createStore<StoreState, any, any, any>(
  createRootReducer(history),
  {} as any,
  composeEnhancer(applyMiddleware(...middleware))
);

Amplify.configure({
  Auth: {
    region: "US-EAST-1",
    userPoolWebClientId: userPoolWebClientId,
    mandatorySignIn: false,
    userPoolId: userPoolId
  },
  aws_appsync_graphqlEndpoint: graphqlEndpoint,
  aws_appsync_region: "US-EAST-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
});

config.set({ baseRedirectURL: "https://s.applecodes.co/" });

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Authenticator hide={[Greetings, SignIn]}>
          <Landing />
          <Unauthorized />
          <App />
        </Authenticator>
      </ConnectedRouter>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
