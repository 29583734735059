import * as React from "react";
import { connect } from "react-redux";
import { createUser } from "../redux/actions";
import validator from "validator";

interface Props {
  createUser: (email: string) => void;
}

interface State {
  email: string;
  isAddDisabled: boolean;
}

class AddUser extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      isAddDisabled: true,
      email: ""
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailChange(event: any) {
    let email = event.target.value;
    let isAddDisabled = true;
    if (validator.isEmail(email)) {
      isAddDisabled = false;
    }

    this.setState({ email, isAddDisabled });
  }

  cleanUp() {
    this.setState({
      isAddDisabled: true,
      email: ""
    });
  }

  handleSubmit(event: any) {
    event.preventDefault();
    this.cleanUp();
    this.props.createUser(this.state.email);
  }

  public render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="uk-flex uk-flex-middle uk-flex-between">
          <label className="uk-form-label uk-margin-right uk-width-expand">
            Email
            <input
              className="uk-input"
              placeholder="appleseed_john@mac.com"
              onChange={this.handleEmailChange}
              type="email"
              value={this.state.email}
            />
          </label>
          <button
            className="uk-icon-button uk-button-primary tm-button-new uk-icon uk-flex-none"
            uk-icon="plus"
            uk-tooltip="Invite User"
            title=""
            onClick={this.handleSubmit}
            disabled={this.state.isAddDisabled}
          />
        </div>
        <hr />
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    createUser: (email: string) => {
      dispatch(createUser(email));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(AddUser);
