import * as React from "react";
import { Auth } from "aws-amplify";
import { ResultType } from "../types";
import { Alert } from ".";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface Props {
  onError?: (err: Error) => void;
  showDashboard: () => void;
}

interface State {
  updateResult?: ResultType<string>;
  email?: string;
  firstName?: string;
  lastName?: string;
}

class UpdateProfile extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.dismissAlert = this.dismissAlert.bind(this);
    this.onUpdateSettings = this.onUpdateSettings.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      email: "",
      firstName: "",
      lastName: ""
    };
  }

  async componentWillMount() {
    try {
      let userInfo = await Auth.currentUserInfo();
      this.setState({
        email: userInfo.attributes["email"],
        firstName: userInfo.attributes["given_name"],
        lastName: userInfo.attributes["family_name"]
      });
    } catch (err) {
      console.error(err);
      this.presentError(Error("Something went wrong."));
      if (this.props.onError) this.props.onError(err);
    }
  }

  handleInputChange(event: any) {
    const target = event.currentTarget;
    const name = target.name;
    this.setState({ [name]: target.value } as State);
  }

  async onUpdateSettings(event: any) {
    event.preventDefault();
    this.dismissAlert();

    const firstName = this.state.firstName;
    const lastName = this.state.lastName;

    try {
      let user = await Auth.currentAuthenticatedUser();
      let result = await Auth.updateUserAttributes(user, {
        given_name: firstName,
        family_name: lastName
      });

      if (result === "SUCCESS") {
        this.setState({
          updateResult: result
        });
      } else {
        console.error(result);
        this.presentError(Error("Something went wrong."));
      }
    } catch (err) {
      console.error(err);
      this.presentError(Error("Something went wrong."));
      if (this.props.onError) this.props.onError(err);
    }
  }

  presentError(error: Error) {
    this.setState({
      updateResult: Error(`☔️ ${error.message}`)
    });
  }

  dismissAlert() {
    this.setState({
      updateResult: undefined
    });
  }

  public render() {
    return (
      <div className="uk-container">
        <form onSubmit={this.onUpdateSettings} className="uk-form-stacked">
          <div className="uk-margin">
            <label className="uk-form-label">
              Email
              <input
                className="uk-input"
                disabled={true}
                value={this.state.email}
              />
            </label>
          </div>
          <div className="uk-margin">
            <label className="uk-form-label">
              First Name
              <input
                name="firstName"
                className="uk-input"
                value={this.state.firstName}
                onChange={this.handleInputChange}
              />
            </label>
          </div>
          <div className="uk-margin">
            <label className="uk-form-label">
              Last Name
              <input
                name="lastName"
                className="uk-input"
                value={this.state.lastName}
                onChange={this.handleInputChange}
              />
            </label>
          </div>
          <hr />
          <Alert
            resultType={this.state.updateResult}
            successMessage="🎉 Your profile has been updated"
            onClose={this.dismissAlert}
          />
          <input
            type="submit"
            value="Update Settings"
            className="uk-button uk-button-primary uk-float-right"
          />
          <input
            value="Cancel"
            className="uk-button uk-button-default uk-float-left uk-margin-large-bottom"
            onClick={() => this.props.showDashboard()}
          />
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    showDashboard: () => {
      dispatch(push("/"));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(UpdateProfile);
