import React from "react";
import { ResultType } from "../types";
import cx from "classnames";

interface Props {
  resultType?: ResultType<any>;
  successMessage: string;
  onClose: () => void;
}

export default class Alert extends React.Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.alertMessage = this.alertMessage.bind(this);
    this.alertMessage = this.alertMessage.bind(this);
  }

  alertStyle(): string {
    return this.props.resultType instanceof Error
      ? "uk-alert-danger"
      : "uk-alert-primary";
  }

  alertMessage(): string {
    let message = "";
    if (this.props.resultType instanceof Error) {
      message = this.props.resultType.message;
    } else if (this.props.resultType !== undefined) {
      message = this.props.successMessage;
    }

    return message;
  }

  componentDidUpdate() {
    if (this.props.resultType !== undefined) {
      setTimeout(this.props.onClose, 60000);
    }
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  public render() {
    return (
      <div
        className={cx(
          "uk-flex",
          "uk-flex-between",
          "uk-flex-middle",
          this.alertStyle()
        )}
        style={{
          padding: "15px",
          marginBottom: "10px"
        }}
        hidden={this.props.resultType === undefined}
      >
        <span>{this.alertMessage()}</span>
        <button
          className="uk-icon"
          uk-icon="icon: close"
          onClick={this.props.onClose}
        />
      </div>
    );
  }
}
