import { RouterState } from "connected-react-router";
import * as bs58 from "bs58";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}

export type ResultType<T> = T | Error;

export interface ShortLinkState {
  rawShortLinks: ShortLink[];
  startIndex: number;
  page: number;
}

export enum Command {
  None = "None",
  Insert = "Insert",
  Update = "Update",
  Delete = "Delete",
  SystemError = "SystemError",
  FormatError = "FormatError"
}

export interface BatchOperationResult {
  id?: number;
  shortLinkURL?: ShortLink;
  redirectURL?: string;
  command: Command;
  applied?: boolean;
  error?: string;
}

export interface Action<T> {
  type: Actions;
  payload: ResultType<T>;
}

export interface User {
  userName: string;
  email: string;
  firstName?: string;
  lastName?: string;
  groups: string[];
}

export interface AppState {
  shortLinks: ShortLinkState;
  foundShortLinks: ShortLinks[];
  lastResult?: ResultType<any>;
  users: User[];
  domains: Domain[];
  updateShortLinkURLsResult: UpdateShortLinkURLOperationResult[];
  exportSearchResult?: {
    count: number;
    head?: ShortLink[];
    tail?: ShortLink[];
  };
}

export interface Domain {
  id: number;
  fqdn: string;
}

export interface StoreState {
  state: AppState;
  router: RouterState;
}

export enum Actions {
  DeleteShortLink = "delete_short_link",
  PushShortLink = "push_short_link",
  UpdateShortLinkResult = "update_short_link",
  SearchShortLinks = "search_short_links",
  PullShortLinks = "pull_short_links",
  PullShortLinksCompleted = "pull_short_links_completed",
  HandleError = "handle_error",
  ErrorDismissed = "error_dismissed",
  PullUsers = "pull_users",
  PullDomains = "pull_domains",
  UpdateShortLinkURLs = "update_short_link_urls",
  ExportShortLinksSearch = "export_short_links_search"
}

export interface DefaultAction {
  type: Actions;
  payload: any;
}

export interface PullShortLinksCompletedAction {
  type: Actions;
  payload: {
    page: number;
    shortLinks?: ShortLink[];
  };
}

export interface UpdateShortLinkURLOperationResult {
  shortLinkURL: string;
  redirectURL: string;
  success: string;
  error: string;
}

export interface UpdateShortLinkURLOperation {
  shortLinkURL: string;
  redirectURL: string;
  command: string;
  applied: boolean;
}

export class ShortLink {
  id: number;
  url: string;
  alias: string;
  createdAt: Date;
  updatedAt: Date;
  tag: string;
  domainId: number;
  fqdn: string;
  queryParameters: string;

  constructor({
    id,
    url,
    alias,
    createdAt,
    updatedAt,
    domainId,
    queryParameters
  }) {
    this.id = id || 0;
    this.url = url;
    this.alias = alias;
    this.createdAt = new Date(createdAt);
    this.updatedAt = new Date(updatedAt);
    this.domainId = domainId;
    this.queryParameters = queryParameters;
    if (id) {
      const bytes = Buffer.from(`${id}`, "utf8");
      this.tag = bs58.encode(bytes);
    }
  }
}
