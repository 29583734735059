import * as React from "react";
import styled from "styled-components";
import validator from "validator";

interface Props {
  onChange?: (url: string, value: string, isValid: boolean) => void;
  url?: string;
  accessory: string;
  placeholder?: string;
}

interface State {
  urlSubstring: string;
  destinationURLError: boolean;
}

const Container = styled.div`
  border: 1px solid #e5e5e5;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  -webkit-transition-property: color, background-color, border;
  transition-property: color, background-color, border;
  :focus-within {
    border: 1px solid #68ae0d;
  }
`;

const Input = styled.input`
  width: 100%;
  border: unset !important;
  padding-left: 0 !important;
`;

const Accessory = styled.div`
  margin: 0 0 0 12px;
  display: inline-flex;
  justify-content: left;
  align-items: center;
  color: #aeaeae;
  padding-left: 0;
`;

export default class URLInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      urlSubstring: "",
      destinationURLError: false
    };

    this.handleURLChange = this.handleURLChange.bind(this);
  }

  handleURLChange(event: any) {
    let urlSubstring = event.target.value;
    let destinationURLError = false;
    let accessory = this.props.accessory;
    let url = `${accessory}${urlSubstring}`;
    if (!validator.isURL(url)) {
      destinationURLError = true;
    }

    this.setState({
      destinationURLError: destinationURLError,
      urlSubstring: urlSubstring
    });

    if (this.props.onChange) {
      this.props.onChange(url, urlSubstring, destinationURLError);
    }
  }

  public renderLoading() {
    return <div className="uk-spinner" />;
  }

  public render() {
    let urlSubstring = "";
    if (this.props.url) {
      let url = this.props.url;
      let accessory = this.props.accessory;
      urlSubstring = url.replace(`${accessory}`, "");
    }

    return (
      <Container className="uk-flex">
        <Accessory>{this.props.accessory}</Accessory>
        <Input
          className={
            this.state.destinationURLError
              ? "uk-input uk-form-danger"
              : "uk-input"
          }
          type="text"
          value={urlSubstring}
          placeholder={this.props.placeholder}
          onChange={this.handleURLChange}
        />
      </Container>
    );
  }
}
