import * as React from "react";
import { connect } from "react-redux";
import { pullShortLinks } from "../redux/actions";
import { API, graphqlOperation } from "aws-amplify";
import * as Observable from "zen-observable";
import { onShortLinkMutation } from "../graphql/subscriptions";

interface Props {
  pullShortLinks: () => void;
}

interface State {
  subscriptions: Observable<object>[];
}

class Synchronizer extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { subscriptions: [] };
    this.subscribe = this.subscribe.bind(this);
    this.eventReceived = this.eventReceived.bind(this);
    this.subscribe(onShortLinkMutation);
  }

  subscribe(query: any) {
    const subscription = API.graphql(graphqlOperation(query, {})) as Observable<
      object
    >;
    subscription.subscribe({ next: this.eventReceived });
    this.state.subscriptions.push(subscription);
  }

  eventReceived() {
    this.props.pullShortLinks();
  }

  public render() {
    return <div>{this.props.children}</div>;
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    pullShortLinks: () => {
      dispatch(pullShortLinks());
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Synchronizer);
