import * as React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import styled from "styled-components";
import { CSVLink } from "react-csv";
import * as Sentry from "@sentry/browser";
import { API, graphqlOperation } from "aws-amplify";
import { BreadCrumbs, CSVInput } from ".";
import {
  ShortLink,
  StoreState,
  UpdateShortLinkURLOperationResult
} from "../types.d";
import * as mutations from "../graphql/mutations";
import * as csvData from "./csvData";

interface Props {
  redirectToRoute: (route: string) => void;
  updateResults: UpdateShortLinkURLOperationResult[];
}

const Link = styled.a`
  color: #409200 !important;
`;

interface State {
  updateOperations?: UpdateOperation[];
  progress: number;
  whichStage: string;
  error: boolean;
  csvContent?: UpdateOperation[];
}

enum CSVColumns {
  redirect = 2,
  shortlink = 1,
  id = 0
}

interface UpdateOperation {
  id?: number;
  redirectURL?: string;
  shortLinkURL?: string;
  applied?: boolean;
  command?: string;
  error?: string;
}

class EditShortLink extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      progress: 0,
      whichStage: "upload",
      error: false
    };
    this.handleCSVContent = this.handleCSVContent.bind(this);
    this.renderWizardSection = this.renderWizardSection.bind(this);
    this.uploadData = this.uploadData.bind(this);
    this.handleBackToUploadClick = this.handleBackToUploadClick.bind(this);
  }

  // Loading animation
  renderLoading() {
    return <div uk-spinner="ratio: 2" className="tm-spinner" />;
  }

  csvPreviewData: any = [];

  csvResultsData: any = [];

  // Data functions
  handleCSVContent = (data: any) => {
    this.setState({ whichStage: "loading" });
    data.shift(); // Drop csv headers
    let updateOperations = data
      .filter((item: any) => item.length === 4)
      .map((item: any) => {
        return {
          id:
            !!item[CSVColumns.id] && item[CSVColumns.id].length > 0
              ? parseInt(item[0])
              : 0,
          shortLinkURL: item[CSVColumns.shortlink],
          redirectURL: item[CSVColumns.redirect]
        } as UpdateOperation;
      });

    this.setState({ csvContent: updateOperations });
    this.getCommands();
  };

  async getCommands() {
    try {
      const response = (await API.graphql(
        graphqlOperation(mutations.batchOperation, {
          operations: this.state.csvContent,
          dryRun: true
        })
      )) as any;
      const updateOperations = response.data.batchOperation
        .operations as UpdateOperation[];
      this.csvPreviewData = updateOperations;
      this.setState({ updateOperations });
      this.setState({ whichStage: "preview" });
      this.setState({ progress: this.state.progress + 1 });
    } catch (err) {
      Sentry.captureException(err);
      this.setState({ error: true });
      console.error(err);
    }
  }

  async uploadData() {
    this.setState({ whichStage: "loading" });
    try {
      const response = (await API.graphql(
        graphqlOperation(mutations.batchOperation, {
          operations: this.state.csvContent,
          dryRun: false
        })
      )) as any;
      const updateOperations = response.data.batchOperation
        .operations as UpdateOperation[];
      this.csvResultsData = updateOperations;
      this.setState({ updateOperations });
      this.setState({ progress: this.state.progress + 1 });
      this.setState({ whichStage: "updates" });
    } catch (err) {
      Sentry.captureException(err);
      this.setState({ error: true });
      console.error(err);
    }
  }

  // Button navigation
  handleBackToUploadClick() {
    this.setState({ progress: this.state.progress - 1 });
    this.setState({ whichStage: "upload" });
    this.accessoryButton = <CSVInput onDataAvailable={this.handleCSVContent} />;
  }
  // Generate on-screen report of upload success
  updateStatus(dataType: string) {
    if (this.state.updateOperations) {
      let info = this.state.updateOperations.filter(
        operation => operation.command === dataType
      );
      let typeName;
      if (dataType === "None") {
        typeName = "No Changes";
      }
      if (dataType === "Insert") {
        typeName = "Additions";
      }
      if (dataType === "Update") {
        typeName = "Updates";
      }
      if (dataType === "Delete") {
        typeName = "Deletions";
      }
      if (dataType === "SystemError") {
        typeName = "System Errors";
      }
      if (dataType === "FormatError") {
        typeName = "Format Errors";
      }

      let totalApplied = info.filter(result => result.applied);
      let totalFailed = info.filter(result => !result.applied);
      let summary;
      let targetDataTypes = ["None", "SystemError", "FormatError"];
      if (info.length && targetDataTypes.includes(dataType)) {
        summary = (
          <h3 className="uk-text-center">
            {`${typeName}: ${totalFailed.length} links`}
          </h3>
        );
      } else if (info.length) {
        summary = (
          <h3 className="uk-text-center">
            {`${typeName}: `}
            <span className="uk-text-primary">{`${totalApplied.length}`}</span>
            <span> applied, </span>
            <span className="uk-text-danger">{`${totalFailed.length}`}</span>
            <span> failed</span>
          </h3>
        );
      }
      return <div>{summary}</div>;
    }
  }

  // Table data display determinations
  content = <></>;
  accessoryButton = <CSVInput onDataAvailable={this.handleCSVContent} />;
  wizardSection: any;

  generateTableData(dataType: string) {
    if (this.state.updateOperations) {
      let info = this.state.updateOperations.filter(
        operation => operation.command === dataType
      );

      let typeName;
      if (dataType === "None") {
        typeName = "No Changes";
      }
      if (dataType === "Insert") {
        typeName = "Additions";
      }
      if (dataType === "Update") {
        typeName = "Updates";
      }
      if (dataType === "Delete") {
        typeName = "Deletions";
      }
      if (dataType === "SystemError") {
        typeName = "System Errors";
      }
      if (dataType === "FormatError") {
        typeName = "Format Errors";
      }
      let rows;
      let summary = <h3>{`${typeName}: ${info.length}`}</h3>;
      if (info.length < 4 && info.length > 0) {
        rows = info.map((operation, i) => {
          return (
            <tr key={operation.shortLinkURL}>
              <td className="uk-text-left">{i + 1}</td>
              <td className="uk-text-left">
                <Link href={operation.shortLinkURL}>
                  {operation.shortLinkURL}
                </Link>
              </td>
              <td className="uk-text-left">
                <Link href={operation.redirectURL}>
                  {operation.redirectURL}
                </Link>
              </td>
              <td className="uk-text-left">{operation.command}</td>
            </tr>
          );
        });
        return (
          <>
            {summary}
            <table className="uk-table uk-table-divider uk-table-small uk-table-justify">
              <thead>
                <tr>
                  <th className="uk-table-shrink" />
                  <th className="uk-table-shrink">Short Link Alias URL</th>
                  <th className="uk-table-shrink">Redirect URL</th>
                  <th className="uk-table-shrink">Edits</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </>
        );
      }
      if (info.length >= 4) {
        rows = (
          <>
            <tr key={info[0].shortLinkURL}>
              <td className="uk-text-left">1</td>
              <td className="uk-text-left">
                <Link href={info[0].shortLinkURL}>{info[0].shortLinkURL}</Link>
              </td>
              <td className="uk-text-left">
                <Link href={info[0].redirectURL}>{info[0].redirectURL}</Link>
              </td>
              <td className="uk-text-left">{info[0].command}</td>
            </tr>
            <tr key={info[1].shortLinkURL}>
              <td className="uk-text-left">2</td>
              <td className="uk-text-left">
                <Link href={info[1].shortLinkURL}>{info[1].shortLinkURL}</Link>
              </td>
              <td className="uk-text-left">
                <Link href={info[1].redirectURL}>{info[1].redirectURL}</Link>
              </td>
              <td className="uk-text-left">{info[1].command}</td>
            </tr>
            <tr>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
            </tr>
            <tr key={info[info.length - 1].shortLinkURL}>
              <td className="uk-text-left">{info.length}</td>
              <td className="uk-text-left">
                <Link href={info[info.length - 1].shortLinkURL}>
                  {info[info.length - 1].shortLinkURL}
                </Link>
              </td>
              <td className="uk-text-left">
                <Link href={info[info.length - 1].redirectURL}>
                  {info[info.length - 1].redirectURL}
                </Link>
              </td>
              <td className="uk-text-left">{info[info.length - 1].command}</td>
            </tr>
          </>
        );
        return (
          <>
            {summary}
            <table className="uk-table uk-table-divider uk-table-small uk-table-justify">
              <thead>
                <tr>
                  <th className="uk-table-shrink" />
                  <th className="uk-table-shrink">Short Link Alias URL</th>
                  <th className="uk-table-shrink">Redirect URL</th>
                  <th className="uk-table-shrink">Edits</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </>
        );
      }
    }
  }

  renderWizardSection() {
    if (this.state.whichStage === "upload") {
      this.wizardSection = (
        <div className="uk-container uk-position-relative tm-main">
          <div>
            <h2
              className="uk-text-large uk-text-primary"
              style={{
                fontWeight: 400,
                fontSize: "40px",
                width: "50px",
                display: "inline-block"
              }}
            >
              1
            </h2>
            <h3
              className="uk-margin-right"
              style={{ marginBottom: "5px", display: "inline-block" }}
            >
              Upload Your .CSV File
            </h3>
            <div className="uk-flex uk-flex-center uk-margin-medium-top">
              <h4 className="uk-text-lead">How Edits are Detected</h4>
            </div>
            <div className="uk-flex uk-flex-center">
              <p className="uk-width-1-2 uk-text-center">
                If a short link does not have an ID, a new one will be created.
                If a short link has an ID and a redirect URL, the short link
                will be updated. If a short link has an ID, but does not have a
                redirect URL, the short link will be deleted.
              </p>
            </div>
            <div className="uk-flex uk-flex-center">{this.accessoryButton}</div>
            <div className="uk-flex uk-flex-center uk-margin-medium-top">
              <h4 style={{ fontStyle: "italic" }}>
                Need to create a .CSV for upload?
              </h4>
            </div>
            <div className="uk-flex uk-flex-center">
              <p>
                <CSVLink
                  data={csvData.csvTemplate}
                  headers={csvData.templateHeaders}
                  className="uk-primary"
                >
                  <span uk-icon="download" />
                  Download a Template
                </CSVLink>{" "}
                or{" "}
                <a
                  onClick={() => {
                    this.props.redirectToRoute("/export");
                  }}
                >
                  Export by Search Term
                </a>
              </p>
            </div>
          </div>
        </div>
      );
    }

    if (this.state.whichStage === "loading") {
      this.wizardSection = <div>{this.renderLoading()}</div>;
    }
    if (this.state.whichStage === "preview" && this.state.updateOperations) {
      this.accessoryButton = (
        <button
          className="uk-button uk-button-primary"
          onClick={this.uploadData}
        >
          Start Update
        </button>
      );

      this.content = (
        <div className="uk-width-3-4 uk-align-center">
          {this.generateTableData("None")}
          {this.generateTableData("Insert")}
          {this.generateTableData("Update")}
          {this.generateTableData("Delete")}
          {this.generateTableData("SystemError")}
          {this.generateTableData("FormatError")}
        </div>
      );

      this.wizardSection = (
        <>
          <h2
            className="uk-text-large uk-text-primary"
            style={{
              fontWeight: 400,
              fontSize: "40px",
              width: "50px",
              display: "inline-block"
            }}
          >
            2
          </h2>
          <h3
            className="uk-margin-right"
            style={{ marginBottom: "5px", display: "inline-block" }}
          >
            Preview .CSV Upload
          </h3>
          {this.content}
          <div className="uk-flex uk-flex-center">
            <CSVLink
              data={this.csvPreviewData}
              headers={csvData.previewHeaders}
              className="uk-button uk-button-default uk-margin-medium-bottom"
            >
              <span uk-icon="download" />
              Download Preview
            </CSVLink>
          </div>
          <div className="uk-flex uk-flex-center uk-button-group">
            <button
              className="uk-button uk-button-default uk-margin-medium-right"
              onClick={this.handleBackToUploadClick}
            >
              Back to Upload
            </button>

            {this.accessoryButton}
          </div>
        </>
      );
    }
    if (this.state.whichStage === "updates") {
      this.content = (
        <div className="uk-width-3-4 uk-align-center">
          {this.updateStatus("None")}
          {this.updateStatus("Insert")}
          {this.updateStatus("Update")}
          {this.updateStatus("Delete")}
          {this.updateStatus("SystemError")}
          {this.updateStatus("FormatError")}
        </div>
      );

      this.wizardSection = (
        <>
          <h2
            className="uk-text-large uk-text-primary"
            style={{
              fontWeight: 400,
              fontSize: "40px",
              width: "50px",
              display: "inline-block"
            }}
          >
            3
          </h2>
          <h3
            className="uk-margin-right"
            style={{
              marginBottom: "5px",
              display: "inline-block"
            }}
          >
            Upload Result
          </h3>
          <div className="uk-flex uk-flex-center" />
          {this.content}
          <div className="uk-flex uk-flex-center">
            <CSVLink
              data={this.csvResultsData}
              headers={csvData.resultHeaders}
              className="uk-button uk-button-primary uk-margin-medium-bottom"
            >
              <span uk-icon="download" />
              Download Report
            </CSVLink>
          </div>
          <div className="uk-flex uk-flex-center uk-button-group">
            <button
              className="uk-button uk-button-default uk-margin-right"
              onClick={() => {
                this.props.redirectToRoute("/short-links/");
              }}
            >
              View Short Links
            </button>
            <button
              className="uk-button uk-button-default uk-margin-left"
              onClick={() => {
                this.props.redirectToRoute("/export/");
              }}
            >
              Go to Export
            </button>
          </div>
        </>
      );
    }
  }

  public render() {
    this.renderWizardSection();

    return (
      <div className="tm-main uk-section uk-section-default uk-padding-remove-top">
        <BreadCrumbs crumbs={[["Short Links", "/short-links"], ["Edit"]]} />
        <div className="uk-container uk-position-relative tm-main">
          <h1 className="uk-heading-divider" style={{ marginTop: "40px" }}>
            Edit Short Link URLs
          </h1>
          {this.wizardSection}
        </div>
        <progress
          className="uk-progress uk-width-1-2 uk-align-center uk-margin-large-bottom"
          value={this.state.progress}
          max="2"
        />
      </div>
    );
  }
}

const mapStateToProps = (store: StoreState) => {
  let shortLink = store.router.location.state as ShortLink;
  return {
    shortLink: shortLink,
    updateResults: store.state.updateShortLinkURLsResult
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    redirectToRoute: (route: string) => {
      dispatch(push(route));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditShortLink);
