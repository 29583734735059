import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Section = styled.div`
  height: 45px;
  @extend uk-width-expand;
`;

const Container = styled.div`
  margin-left: 0px !important;
  padding-left: 40px !important;
`;

const Crumbs = styled.ul`
  margin-top: 11px;
`;

const StyledLink = styled(Link)`
  color: #b4b4b4 !important;
`;

interface Props {
  crumbs: string[][];
}

export default class BreadCrumbs extends React.Component<Props, any> {
  public render() {
    let crumbsRendering = [];
    crumbsRendering.push(
      this.props.crumbs.map((crumb, i) => {
        return (
          <li key={i}>
            {crumb.length > 1 ? (
              <StyledLink to={crumb[1]}>{crumb[0]}</StyledLink>
            ) : (
              <span>{crumb[0]}</span>
            )}
          </li>
        );
      })
    );

    return (
      <Section>
        <Container className="uk-container uk-width-expand uk-height-1-1 uk-background-muted">
          <Crumbs className="uk-breadcrumb">{crumbsRendering}</Crumbs>
        </Container>
      </Section>
    );
  }
}
