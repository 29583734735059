/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "../styles/application.scss";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Auth } from "aws-amplify";

interface Props {
  redirectToRoute: (route: string) => void;
  location: string;
}

interface State {
  isSuperAdmin: boolean;
}

class Sidebar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSuperAdmin: false
    };
  }

  async componentWillMount() {
    const session = (await Auth.currentSession()) as any;
    const groups = session.idToken.payload["cognito:groups"];
    const isSuperAdmin = groups.includes("superadmin");
    this.setState({ isSuperAdmin });
  }

  public render() {
    const location = this.props.location;
    const isSuperAdmin = this.state.isSuperAdmin;
    return (
      <div className="tm-sidebar-left uk-visible@m" style={{ top: "60px" }}>
        <ul
          className="uk-nav-default tm-nav tm-primary-nav uk-nav-parent-icon uk-nav"
          uk-nav=""
        >
          <li className={location === "/" ? "uk-active" : ""}>
            <a
              onClick={() => {
                this.props.redirectToRoute("/");
              }}
            >
              Dashboard
            </a>
          </li>
          <li
            className={
              location === "/short-links"
                ? "uk-active"
                : location === "/short-links/new"
                ? "uk-active"
                : ""
            }
          >
            <a
              onClick={() => {
                this.props.redirectToRoute("/short-links");
              }}
            >
              Short Links
            </a>
          </li>

          <li
            className={location === "/short-links/url/edit" ? "uk-active" : ""}
          >
            <a
              onClick={() => {
                this.props.redirectToRoute("/short-links/url/edit");
              }}
            >
              Edit URLs
            </a>
          </li>
          <li className={location === "/export" ? "uk-active" : ""}>
            <a
              onClick={() => {
                this.props.redirectToRoute("/export");
              }}
            >
              Export
            </a>
          </li>
          <hr className="uk-margin-small-top uk-margin-small-bottom" />
          <li className={location === "/help" ? "uk-active" : ""}>
            <a
              onClick={() => {
                this.props.redirectToRoute("/help");
              }}
            >
              Help
            </a>
          </li>
          <hr
            className="uk-margin-small-top uk-margin-small-bottom"
            hidden={!isSuperAdmin}
          />
          <li
            className={
              location === "/admin/users" ? "uk-parent uk-open" : "uk-parent"
            }
            hidden={!isSuperAdmin}
          >
            <a href="#">Admin</a>
            <ul className="uk-nav-sub tm-nav">
              <li className={location === "/admin/users" ? "uk-active" : ""}>
                <a
                  onClick={() => {
                    this.props.redirectToRoute("/admin/users");
                  }}
                >
                  Users
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <div className="uk-position-bottom uk-text-center uk-margin-small-bottom">
          <img alt="lemur icon" src="/images/lemur@2x.png" width="75" height="75" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ router }: any) => {
  return {
    location: router.location.pathname
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    redirectToRoute: (route: string) => {
      dispatch(push(route));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);
