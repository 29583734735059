import * as React from "react";

interface Props {
  errorMessage: string;
  onClose: () => void;
}

export default class Dashboard extends React.Component<Props, any> {
  public render() {
    return (
      <div
        className="flamingo-notification uk-alert-danger uk-alert"
        uk-alert=""
      >
        <button
          className="uk-alert-close uk-close uk-icon"
          uk-close=""
          onClick={this.props.onClose}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              fill="none"
              stroke="#000"
              strokeWidth="1.1"
              x1="1"
              y1="1"
              x2="13"
              y2="13"
            />
            <line
              fill="none"
              stroke="#000"
              strokeWidth="1.1"
              x1="13"
              y1="1"
              x2="1"
              y2="13"
            />
          </svg>
        </button>
        {this.props.errorMessage}
      </div>
    );
  }
}
