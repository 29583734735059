// tslint:disable
// this is an auto generated file. This will be overwritten

export const getShortLink = `query GetShortLink($id: ID!) {
  getShortLink(id: $id) {
    id
    url
    createdAt
    updatedAt
    alias
    queryParameters
    tag
    domainId
  }
}
`;
export const listShortLinks = `query ListShortLinks($page: Int, $limit: Int) {
  listShortLinks(page: $page, limit: $limit) {
    id
    url
    createdAt
    updatedAt
    alias
    queryParameters
    tag
    domainId
  }
}
`;
export const searchShortLinks = `query SearchShortLinks($query: String) {
  searchShortLinks(query: $query) {
    id
    url
    createdAt
    updatedAt
    alias
    queryParameters
    tag
    domainId
  }
}
`;
export const listUsers = `query ListUsers {
  listUsers {
    userName
    firstName
    lastName
    email
    groups
  }
}
`;

export const listDomains = `query listDomains {
  listDomains {
    id
    fqdn
  }
}
`;
