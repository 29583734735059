import * as React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import {
  pullShortLinks,
  pullNextShortLinks,
  fetchDomains
} from "../../redux/actions";
import { ShortLink, StoreState, Domain } from "../../types";
import ShortLinkRow from "../shortLinkRow";
import BreadCrumbs from "../breadCrumbs";
import "../../styles/theme/custom/_spinner.scss";

interface IShortLinksProps {
  shortLinks: ShortLink[];
  domains: Domain[];
  pullShortLinks: () => void;
  pullNextShortLinks: () => void;
  pullDomains: () => void;
  redirectToRoute: (route: string) => void;
  editShortLink: (shortLink: ShortLink) => void;
}

class ShortLinksIndex extends React.Component<IShortLinksProps, any> {
  componentWillMount() {
    this.props.pullDomains();
    this.props.pullShortLinks();
  }

  public renderShortLinks(shortLinks: ShortLink[]) {
    let shortLinkRows = shortLinks.flatMap(shortLink => {
      return <ShortLinkRow shortLink={shortLink} key={shortLink.id} />;
    });
    return (
      <div className="tm-main uk-section uk-section-default uk-padding-remove-top">
        <BreadCrumbs crumbs={[["Short Links"]]} />
        <div className="uk-container uk-position-relative tm-main">
          <h1 className="uk-heading-divider">
            Short Links
            <button
              className="uk-icon-button uk-button-primary uk-float-right tm-button-new uk-icon"
              uk-icon="plus"
              uk-tooltip="New Short Link"
              onClick={() => {
                this.props.redirectToRoute("/short-links/new");
              }}
            />
          </h1>
          <div className="uk-flex uk-flex-between uk-flex-middle uk-margin-top">
            <div className="pagination-info">
              Displaying <b>{shortLinks.length}</b> short links
            </div>
          </div>

          <table className="uk-table uk-table-divider uk-table-small">
            <thead>
              <tr>
                <th>url</th>
                <th>short link</th>
                <th>alias</th>
                <th />
              </tr>
            </thead>
            <tbody>{shortLinkRows}</tbody>
          </table>
        </div>
      </div>
    );
  }

  public renderLoading() {
    return <div uk-spinner="ratio: 2" className="tm-spinner" />;
  }

  public render() {
    let shortLinks = this.props.shortLinks;
    let domains = this.props.domains;
    let content = undefined;
    if (shortLinks && domains.length > 0) {
      shortLinks = shortLinks.map((s: ShortLink) => {
        const domain = domains.find((d: Domain) => d.id === s.domainId);
        if (domain) {
          s.fqdn = domain.fqdn;
        }
        return s;
      });

      content = this.renderShortLinks(shortLinks);
    } else {
      content = this.renderLoading();
    }

    return content;
  }
}

const mapStateToProps = ({ state }: StoreState) => {
  return {
    shortLinks: state.shortLinks.rawShortLinks,
    domains: state.domains
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    pullShortLinks: () => {
      dispatch(pullShortLinks(1));
    },
    pullNextShortLinks: () => {
      dispatch(pullNextShortLinks());
    },
    pullDomains: () => {
      dispatch(fetchDomains());
    },
    redirectToRoute: (route: string) => {
      dispatch(push(route));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShortLinksIndex);
