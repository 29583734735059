export const templateHeaders = [
  { label: "ID", key: "id" },
  { label: "Alias URL", key: "shortLinkURL" },
  { label: "Redirect URL", key: "redirectURL" },
  { label: "Short Link", key: "shortLink" }
];

export const previewHeaders = [
  { label: "ID", key: "id" },
  { label: "Short Link Alias URL", key: "shortLinkURL" },
  { label: "Redirect URL", key: "redirectURL" },
  { label: "Action", key: "command" },
  { label: "Error", key: "error" }
];

export const resultHeaders = [
  { label: "ID", key: "id" },
  { label: "Redirect URL", key: "redirectURL" },
  { label: "Short Link Alias URL", key: "shortLinkURL" },
  { label: "Action", key: "command" },
  { label: "Success", key: "applied" },
  { label: "Error", key: "error" }
];

export const csvTemplate: any = [
  {
    id: "existing id or empty for new link",
    shortLink: `http://s.fruit.sh/123456 or empty for new link`,
    shortLinkURL: "http://s.fruit.sh/example",
    redirectURL: "http://example.com",
    domainId: "optional"
  }
];
