// tslint:disable
// this is an auto generated file. This will be overwritten

export const onShortLinkMutation = `subscription OnShortLinkMutation {
  onShortLinkMutation {
    id
    url
    createdAt
    updatedAt
    alias
    queryParameters
    tag
  }
}
`;
