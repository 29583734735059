/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "../styles/application.scss";
import { Auth } from "aws-amplify";
import { StoreState, ShortLink } from "../types";
import { searchShortLinks } from "../redux/actions";
import { connect } from "react-redux";
import ShortLinkRow from "./shortLinkRow";
import { push } from "connected-react-router";
import * as Sentry from "@sentry/browser";

interface State {
  email: string;
  initials: string;
  searchQuery?: string;
  isSearchHidden: boolean;
}

interface Props {
  onStateChange?(state: any, data: any): any;
  foundShortLinks?: ShortLink[];
  searchShortLinks(query: string): void;
  showSettings(): void;
}

class Header extends React.Component<Props, State> {
  searchInput: any;
  closeButton: any;

  constructor(props: any) {
    super(props);
    this.state = {
      initials: "",
      email: "",
      isSearchHidden: true
    };
    this.searchInput = React.createRef();
    this.closeButton = React.createRef();
    this.logOut = this.logOut.bind(this);
    this.startSearch = this.startSearch.bind(this);
    this.stopSearch = this.stopSearch.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.renderResultsTable = this.renderResultsTable.bind(this);
  }

  computeInitials(
    email: string,
    firstName?: string,
    lastName?: string
  ): string {
    if (firstName) {
      firstName = firstName.substr(0, 1).toUpperCase();
    }
    if (lastName) {
      lastName = lastName.substr(0, 1).toUpperCase();
    }
    if (email) {
      email = email.substr(0, 1).toUpperCase();
    }

    let initials = "";
    if (firstName && lastName) {
      initials = `${firstName}${lastName}`;
    } else if (firstName) {
      initials = firstName;
    } else if (lastName) {
      initials = lastName;
    } else {
      initials = email;
    }
    return initials;
  }

  async componentWillMount() {
    try {
      const userInfo = await Auth.currentUserInfo();
      const email = userInfo.attributes["email"];
      const firstName = userInfo.attributes["given_name"];
      const lastName = userInfo.attributes["family_name"];
      const initials = this.computeInitials(email, firstName, lastName);
      this.setState({ initials: initials });
      this.setState({ email: email });
      Sentry.setUser({ email: email });
    } catch (err) {}
  }

  async logOut() {
    try {
      await Auth.signOut();
      const { onStateChange } = this.props;
      onStateChange!("signedOut", null);
    } catch (err) {}
  }

  startSearch() {
    this.searchInput.current.autofocus = true;
    this.searchInput.current.focus();
    console.log("search started");
  }

  query: string = "";

  onSearch(event: any) {
    this.query = event.target.value;
    this.props.searchShortLinks(this.query);
  }

  stopSearch() {
    this.closeButton.current.click();
  }

  loading = <></>;

  renderResultsTable() {
    const shortLinks = this.props.foundShortLinks;
    if (this.query.length) {
      console.log("in if");
      this.loading = (
        <div className="uk-flex uk-flex-center">
          <div uk-spinner="ratio: 2" />
        </div>
      );
      
    }

    if (!shortLinks || shortLinks.length === 0) {
      this.loading = <></>;
      return (
        <div className="no-results" id="tmSearchResultNoEntries">
          No Results
        </div>
      );
    }

    let shortLinkRows = shortLinks.flatMap(shortLink => {
      this.loading = <></>;
      return (
        <ShortLinkRow
          shortLink={shortLink}
          key={shortLink.id}
          onEditShortLink={this.stopSearch}
        />
      );
    });

    return (
      <div id="tmSearchResultEntries">
        {this.loading}
        <table className="uk-table uk-table-small uk-table-divider uk-margin-remove-bottom tm-SearchResultsTable">
          <thead>
            <tr>
              <th>url</th>
              <th>shortlink</th>
              <th>alias</th>
              <th />
            </tr>
          </thead>
          <tbody>{shortLinkRows}</tbody>
        </table>
      </div>
    );
  }

  public render() {
    const resultsTable = this.renderResultsTable();
    return (
      <React.Fragment>
        <div className="tm-sticky tm-search-nav">
          <nav className="uk-navbar-container" uk-navbar="">
            <div className="nav-overlay uk-navbar-left">
              <ul className="uk-navbar-nav">
                <li>
                  <a className="uk-text-capitalize" href="/">
                     Lemur
                  </a>
                </li>
              </ul>
            </div>
            <div className="nav-overlay uk-navbar-right" id="tmSearchBar">
              <ul className="uk-navbar-nav">
                <li className="uk-inline">
                  <button
                    className="uk-navbar-toggle tm-navbar-search-icon"
                    uk-search-icon=""
                    uk-toggle="target: .nav-overlay; animation: uk-animation-fade"
                    onClick={this.startSearch}
                  />
                </li>
                <li className="uk-inline">
                  <div>
                    <a className="initials uk-border-circle uk-text-center">
                      {this.state.initials}
                    </a>
                  </div>
                  <div uk-dropdown="mode: click">
                    <ul className="uk-nav uk-dropdown-nav">
                      <li>{this.state.email}</li>
                      <br />
                      <li>
                        <a onClick={this.props.showSettings}>Settings</a>
                      </li>
                      <li>
                        <a onClick={this.logOut}>Logout</a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div
              className="nav-overlay uk-navbar-left uk-flex-1 tm-main"
              hidden={true}
            >
              <div className="uk-navbar-item uk-width-expand">
                <form className="uk-search uk-search-navbar uk-width-1-1">
                  <input
                    className="uk-search-input"
                    id="tmSearchInput"
                    autoFocus
                    placeholder="Search..."
                    type="search"
                    value={this.state.searchQuery}
                    ref={this.searchInput}
                    onChange={this.onSearch}
                  />
                </form>
              </div>
              <button
                className="uk-navbar-toggle uk-close-large"
                uk-close=""
                uk-toggle="target: .nav-overlay; animation: uk-animation-fade"
                ref={this.closeButton}
              />
            </div>
          </nav>
        </div>
        <div
          className="nav-overlay tm-search-results uk-padding uk-margin-remove uk-overlay uk-overlay-primary uk-animation-fade uk-animation-fast"
          id="tmSearchResults"
          uk-height-viewport="offset-bottom: 80px"
          hidden={true}
        >
          <div
            className="uk-card uk-card-default uk-card-small uk-card-body uk-box-shadow-xlarge uk-border-rounded"
            style={{ borderRadius: "0" }}
          >
            {resultsTable}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ state }: StoreState) => {
  return {
    foundShortLinks: state.foundShortLinks
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    searchShortLinks: (query: string) => {
      dispatch(searchShortLinks(query));
    },
    showSettings: () => {
      dispatch(push("/settings"));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
