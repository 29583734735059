import React from "react";
import * as Sentry from "@sentry/browser";

interface State {
  error: Error | null;
}

export default class ErrorBoundary extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error | null, info: any) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(info).forEach(key => {
        scope.setExtra(key, info[key]);
      });
      Sentry.captureException(error);
    });
  }

  public render() {
    if (this.state.error) {
      Sentry.showReportDialog();
      return <div />;
    } else {
      return this.props.children;
    }
  }
}
