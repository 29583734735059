import React from "react";
interface Props {
  message: string;
  onRetry?: () => void;
  onClose: () => void;
  hidden: boolean;
}

export default class RetryBanner extends React.Component<Props, any> {
  componentDidUpdate() {
    if (!this.props.hidden) {
      setTimeout(this.props.onClose, 60000);
    }
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  public render() {
    return (
      <div className="uk-alert-danger" uk-alert="" hidden={this.props.hidden}>
        <button
          className="uk-alert-close"
          uk-close=""
          onClick={this.props.onClose}
        />
        <p>{this.props.message}</p>
      </div>
    );
  }
}
