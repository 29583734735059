import * as React from "react";
import { connect } from "react-redux";
import { pullShortLinks, pullNextShortLinks } from "../redux/actions";
import { ShortLink, StoreState } from "../types";

interface IShortLinksProps {
  shortLinks: ShortLink[];
  pullShortLinks: () => void;
  pullNextShortLinks: () => void;
  editShortLink: (shortLink: ShortLink) => void;
}

class Dashboard extends React.Component<IShortLinksProps, any> {
  componentWillMount() {
    this.props.pullShortLinks();
  }

  public renderShortLinks(shortLinks: ShortLink[]) {
    return (
      <div className="tm-main-container uk-container tm-main uk-padding-remove-top">
        <div className="tm-main-container uk-container uk-position-relative tm-main uk-padding-remove-top">
          <h1 className="uk-heading-divider ">Dashboard</h1>
          <h4>Coming soon</h4>
        </div>
      </div>
    );
  }

  public renderLoading() {
    return <div className="uk-spinner" />;
  }

  public render() {
    let shortLinks = this.props.shortLinks;

    let content = undefined;
    if (shortLinks) {
      content = this.renderShortLinks(shortLinks);
    } else {
      content = this.renderLoading();
    }

    return content;
  }
}

const mapStateToProps = ({ state }: StoreState) => {
  return {
    shortLinks: state.shortLinks.rawShortLinks
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    pullShortLinks: () => {
      dispatch(pullShortLinks(1));
    },
    pullNextShortLinks: () => {
      dispatch(pullNextShortLinks());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
