import React, { Component } from "react";
import "./styles/application.scss";
import {
  EditShortLink,
  Dashboard,
  Help,
  Settings,
  Users,
  EditShortLinkURLs
} from "./components";
import Header from "./components/header";
import { ShortLinksList, ShortLinksNew } from "./components/shortLinks/";
import Export from "./components/export/export";
import Sidebar from "./components/sidebar";
import Synchronizer from "./components/synchronizer";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { fetchDomains } from "./redux/actions";

interface Props {
  authState?: string;
  authData?: any;
  onStateChange?(state: any, data: any): any;
  fetchDomains(): any;
  showUnauthorized(): void;
}

class App extends Component<Props> {
  componentDidMount() {
    this.props.fetchDomains();
  }

  public render() {
    let isAuthenticated = this.props.authData !== undefined;
    let isAuthorized = false;
    if (isAuthenticated) {
      const authData = this.props.authData;
      const groups =
        authData.signInUserSession.idToken.payload["cognito:groups"];
      isAuthorized = groups.includes("superadmin") || groups.includes("lemur");
    }

    if (isAuthenticated && isAuthorized) {
      return (
        <div className="App">
          <Synchronizer />
          <Header onStateChange={this.props.onStateChange} />
          <Sidebar />

          <div className="tm-main ">
            <Route path="/" exact={true} component={Dashboard} />
            <Route
              path="/short-links"
              exact={true}
              component={ShortLinksList}
            />
            <Route
              path="/short-links/url/edit"
              exact={true}
              component={EditShortLinkURLs}
            />
            <Route path="/help" exact={true} component={Help} />
            <Route path="/edit" exact={true} component={EditShortLink} />
            <Route
              path="/short-links/new"
              exact={true}
              component={ShortLinksNew}
            />
            <Route path="/export" exact={true} component={Export} />
            <Route path="/settings" exact={true} component={Settings} />
            <Route path="/admin/users" exact={true} component={Users} />
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    showUnauthorized: () => {
      dispatch(push("/settings"));
    },
    fetchDomains: () => {
      dispatch(fetchDomains());
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(App);
