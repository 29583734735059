import * as React from "react";
import { URLInput, RetryBanner, BreadCrumbs } from ".";
import { ShortLink, StoreState, ResultType } from "../types";
import {
  updateShortLink,
  dismissError,
  deleteShortLink
} from "../redux/actions";
import { connect } from "react-redux";
import { push } from "connected-react-router";

interface Props {
  shortLink: ShortLink;
  updateResult?: ResultType<ShortLink>;
  showDashboard: () => void;
  updateShortLink: (
    id: number,
    url: string,
    alias: string,
    domainId: number
  ) => void;
  dismissError: () => void;
  deleteShortLink: (shortLink: ShortLink) => void;
}

interface State {
  alias: string;
  url: string;
  isUpdateDisabled: boolean;
  destinationURLError: boolean;
  baseRedirectURL: string;
}

class EditShortLink extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.shortLinkURL = this.shortLinkURL.bind(this);
    this.handleURLChange = this.handleURLChange.bind(this);
    this.handleAliasChange = this.handleAliasChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onErrorDismissed = this.onErrorDismissed.bind(this);
    this.onDeleteShortLink = this.onDeleteShortLink.bind(this);

    const domainFQDN = this.props.shortLink.fqdn;
    this.state = {
      alias: this.props.shortLink.alias,
      url: this.props.shortLink.url,
      destinationURLError: false,
      isUpdateDisabled: false,
      baseRedirectURL: `https://${domainFQDN}/`
    };
  }

  handleURLChange(url: string, _: string, isValid: boolean) {
    this.setState({
      url: url,
      isUpdateDisabled: isValid
    });
  }

  handleAliasChange(_: string, value: string, isValid: boolean) {
    this.setState({
      alias: value,
      isUpdateDisabled: isValid
    });
  }

  shouldComponentUpdate(nextProps: Props, _: State) {
    if (!nextProps.shortLink) {
      this.props.showDashboard();
      return false;
    }
    return true;
  }

  shortLinkURL() {
    const baseRedirectURL = this.props.shortLink.fqdn;
    const tag = this.props.shortLink.tag;
    return `${baseRedirectURL}/${tag}`;
  }

  handleSubmit(event: any) {
    this.onErrorDismissed();
    event.preventDefault();
    this.props.updateShortLink(
      this.props.shortLink.id,
      this.state.url,
      this.state.alias,
      this.props.shortLink.domainId
    );
  }

  onErrorDismissed() {
    this.props.dismissError();
  }

  onDeleteShortLink() {
    if (window.confirm("Are you sure you wish to delete this Short Link?")) {
      this.props.deleteShortLink(this.props.shortLink);
    }
  }

  public render() {
    return (
      <div className="tm-main uk-section uk-section-default uk-padding-remove-top">
        <BreadCrumbs crumbs={[["Short Links", "/short-links"], ["Edit"]]} />
        <div className="uk-container uk-position-relative tm-main">
          <h1 className="uk-heading-divider" style={{ marginTop: "40px" }}>
            <div className="uk-flex uk-flex-between uk-flex-middle">
              <div>
                Edit Short Link
                <h3>{this.shortLinkURL()}</h3>
              </div>

              <button
                className="uk-button uk-button-danger uk-align-right"
                onClick={this.onDeleteShortLink}
              >
                Delete
              </button>
            </div>
          </h1>
          <form onSubmit={this.handleSubmit}>
            <div className="uk-margin">
              <label className="uk-form-label">Destination URL</label>
              <URLInput
                accessory="https://"
                url={this.state.url}
                onChange={this.handleURLChange}
                placeholder="example.com"
              />
            </div>
            <div className="uk-margin">
              <label className="uk-form-label uk-margin-right uk-width-2-5">
                Vanity Alias
                <URLInput
                  accessory={this.state.baseRedirectURL}
                  url={this.state.alias}
                  placeholder="alias"
                  onChange={this.handleAliasChange}
                />
              </label>
            </div>
            <p>
              <span className="uk-label">Note</span>
              {
                " If the vanity alias exist in another short link, it will be cleared and assigned to this short link"
              }
            </p>
            <hr />
            <RetryBanner
              message={
                this.props.updateResult
                  ? (this.props.updateResult! as Error).message
                  : ""
              }
              onClose={this.onErrorDismissed}
              hidden={!(this.props.updateResult instanceof Error)}
            />
            <div
              className="uk-alert-primary"
              uk-alert=""
              hidden={
                this.props.updateResult === undefined ||
                this.props.updateResult instanceof Error
              }
            >
              <button className="uk-alert-close" uk-close="" />
              <p><span aria-label="celebrate" role="img">🎉</span> The Short Link is updated and publicly available</p>
            </div>
            <input
              type="submit"
              name="commit"
              value="Update"
              className="uk-button uk-button-primary uk-float-right"
              data-disable-with="Submit"
              disabled={this.state.isUpdateDisabled}
            />
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: StoreState) => {
  let shortLink = store.router.location.state as ShortLink;
  return {
    shortLink: shortLink,
    updateResult: store.state.lastResult
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showDashboard: () => {
      dispatch(push("/"));
    },
    updateShortLink: (
      id: number,
      url: string,
      alias: string,
      domainId: number
    ) => {
      dispatch(updateShortLink(id, url, alias, domainId));
    },
    deleteShortLink: (shortLink: ShortLink) => {
      dispatch(deleteShortLink(shortLink));
    },
    dismissError: () => {
      dispatch(dismissError());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditShortLink);
