import React from "react";
import PapaParse from "papaparse";
import styled from "styled-components";

const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

interface Props {
  onDataAvailable: (data: any) => void;
}

export default class CSVInput extends React.Component<Props> {
  constructor(props: any) {
    super(props);
    this.fileUploaded = this.fileUploaded.bind(this);
  }

  fileUploaded(e: any) {
    let reader = new FileReader();
    reader.onload = (event: any) => {
      const csvData = PapaParse.parse(event.target.result);
      this.props.onDataAvailable(csvData.data);
    };
    reader.readAsText(e.target.files[0]);
  }

  public render() {
    const id = "file-upload-csv";
    return (
      <>
        <FileInput
          type="file"
          accept=".csv, text/csv"
          onChange={this.fileUploaded}
          id={id}
        />
        <label
          className="uk-button uk-button-default uk-button-primary"
          htmlFor={id}
        >
          <span uk-icon="upload" />
          Upload .CSV File
        </label>
      </>
    );
  }
}
