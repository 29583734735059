import React from "react";
import { Auth } from "aws-amplify";
import styled from "styled-components";
import "../styles/authentication.scss";
import "../styles/application.scss";

interface IProps {
  authData?: any;
}

const Background = styled.div`
  background-image: linear-gradient(to bottom right, #409200, #c4ef2b);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export default class Landing extends React.Component<IProps, any> {
  constructor(props: any) {
    super(props);
    this.reloadSession = this.reloadSession.bind(this);
  }

  async reloadSession() {
    let user = await Auth.currentAuthenticatedUser();
    let session = await Auth.currentSession();
    let refreshToken = session.getRefreshToken();
    user.refreshSession(refreshToken, () => {
      window.location.reload();
    });
  }

  public render() {
    let isAuthenticated = this.props.authData !== undefined;
    let isAuthorized = false;
    if (isAuthenticated && this.props.authData) {
      const authData = this.props.authData;
      const groups =
        authData.signInUserSession.idToken.payload["cognito:groups"];
      isAuthorized = groups.includes("superadmin") || groups.includes("lemur");
    }

    if (isAuthenticated && !isAuthorized) {
      return (
        <Background className="authentication">
          <div className="uk-position-center">
            <h1 className="uk-light uk-text-center">&nbsp;Lemur</h1>
            <div className="uk-card uk-card-body uk-card-default uk-text-small uk-padding-remove-vertical uk-text-center uk-width-large">
              <div className="uk-section-xsmall">
                <div className="uk-flex uk-flex-column">
                  <div className="uk-margin-top uk-padding-small uk-padding-remove-vertical">
                    <h4>Not Enough Permissions</h4>
                    <p>
                      Contact the system administrators to request access to
                      this application. When done please recheck the
                      permissions.
                    </p>
                    <input
                      type="button"
                      value="Recheck permissions"
                      className="uk-button uk-button-primary"
                      onClick={this.reloadSession}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Background>
      );
    }

    return null;
  }
}
